import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import api from "../../../api";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Category from "@material-ui/icons/Category";
import Done from "@material-ui/icons/Done";
import TopBar from "../TopBar/TopBar";
import IconButton from "../IconButton/IconButton";
import Title from "../Title/Title";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { getAccessToken } from "../../../utils/cognito.js";
import heic2any from "heic2any";
import prettyBytes from "pretty-bytes";
import "./LoadingImages.css";

const styles = theme => ({
  appBar: {
    position: "relative",
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    marginTop: "15px",
    margin: "0 auto",
    padding: `${theme.spacing.unit * 8}px ${theme.spacing.unit * 4}px ${theme
      .spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 2,
  },
  actionButton: {
    margin: "8px",
    textTransform: "none",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    position: "relative",
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  cardGrid: {
    padding: 0,
  },
  // cardContainer: {
  //   [theme.breakpoints.down("sm")]: {
  //     minWidth: "100%",
  //   },
  // },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  upload: {
    display: "none",
  },
  filename: {},
  spinner: {
    textAlign: "center",
    height: "201px",
    paddingTop: "80px",
  },
  nonFound: {
    fontSize: "1rem",
    textAlign: "center",
    width: "100%",
  },
  storageText: {
    fontSize: "1rem",
    fontWeight: "500",
  },
  infoText: {
    fontSize: "1rem",
    fontWeight: "500",
    paddingTop: "20px",
  },
  loadingSpinner: {
    color: "#fff",
  },
});

const resize = (file, maxWidth, maxHeight, fn) => {
  var reader = new FileReader();
  if (file !== undefined) {
    reader.readAsDataURL(file);
    reader.onload = function(event) {
      var dataUrl = event.target.result;

      var image = new Image();
      image.src = dataUrl;
      image.type = file.type;
      image.onload = function() {
        // getOrientation(file, function(orientation) {
        resizeImage(image, maxWidth, maxHeight, 0.7, 0, function(
          returnedData,
          finalDataURL
        ) {
          fn(returnedData, finalDataURL);
        });
        // });
      };
    };
  }
};

const resizeImage = (
  image,
  maxWidth,
  maxHeight,
  quality,
  orientation,
  returnedData
) => {
  var canvas = document.createElement("canvas");

  var width = image.width;
  var height = image.height;
  var ctx = canvas.getContext("2d");

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  if (4 < orientation && orientation < 9) {
    canvas.width = height;
    canvas.height = width;
  } else {
    canvas.width = width;
    canvas.height = height;
  }

  switch (orientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      break;
  }

  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
      value: function(callback, type, quality) {
        var canvas = this;
        setTimeout(function() {
          var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
            len = binStr.length,
            arr = new Uint8Array(len);

          for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || "image/jpeg" }));
        });
      },
    });
  }
  ctx.drawImage(image, 0, 0, width, height);
  var finalDataURL = canvas.toDataURL(image.type, quality);
  return canvas.toBlob(
    function(blob) {
      returnedData(blob, finalDataURL);
    },
    image.type,
    quality
  );
};

const mediaPath = process.env.REACT_APP_API_MEDIA_PATH;
const apiUrl = process.env.REACT_APP_URL_API_BASE;

class UploadMultipleImages extends Component {
  static propTypes = {
    usedStorageSpace: PropTypes.number.isRequired,
    storageSpace: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      filesNames: [],
      filesProgress: [],
      imagesPreviewUrls: [],
      dataUrl: null,
      resUrl: null,
      newStepId: null,
      loading: false,
      imageSizes: 0,
      progress: 0,
      showSaveDialog: false,
      totalSizeUploaded: [],
      showDeterminateLoader: false,
      defaultImageLimit: 10,
      disabledSelectBtn: false,
      allImagesLoadingDone: false,
    };
    this._handleImageChange = this._handleImageChange.bind(this);
    this.finalImagesArray = [];
    this.workingImagesArray = [];
  }

  transformImagesIntoSteps = async () => {
    this.setState({
      showSaveDialog: false,
      disabledSelectBtn: true,
    });
    var totalSizes = 0;
    this.state.files.forEach((item, index) => {
      totalSizes = totalSizes + item.resizedDataUrl.size;
    });
    this.setState({ imageSizes: totalSizes });
    this.state.files.forEach((item, index) => {
      const progressArray = this.state.filesProgress;
      progressArray[index] = {
        uploading: true,
        uploadingDone: false,
        creatingStep: false,
        creatingStepDone: false,
      };
      this.setState({ filesProgress: progressArray });
      this.startTransform(item, index);
    });
    // this.setState({ loading: true });
  };

  componentDidMount() {
    this.props.addRef(this.props.location.state.newStepId);

    api.getStorageUsed(this.props.self.id).then(value => {
      this.props.updateStorageUsed(value);
    });

    if (_.isEmpty(this.props.tourtleItem)) {
      this.getTourtleItem();
    }

    if (this.props.location.state.fromCoverPage) {
      this.props.onAddingStepFromCoverPageOn();
    }
  }

  getTourtleItem = async () => {
    const {
      onSetActiveTourtleId,
      onFetchTourtleItem,
      onFetchTourtleItemSuccess,
      onFetchTourtleItemFailure,
      userInfo,
      onSnackbarOpening,
    } = this.props;

    try {
      onFetchTourtleItem(this.props.location.state.id);
      onSetActiveTourtleId(this.props.location.state.id);
      const response = await api.fetchTourtleItem(this.props.location.state.id);
      onFetchTourtleItemSuccess(response);

      if (response.statusCode === 403 && !userInfo) {
        this.setState({ showLoginDialog: true });
      }
      if (response.statusCode === 403 && userInfo) {
        onSnackbarOpening("error");
      }
      if (response.statusCode === 404) {
        onSnackbarOpening("notFound");
      }
    } catch (error) {
      onFetchTourtleItemFailure(error);
    }
  };

  componentDidUpdate(prevProps) {
    const { getStepCounter, increaseUsedStorage, history } = this.props;
    if (prevProps.getStepCounter !== getStepCounter) {
      if (getStepCounter === this.state.files.length) {
        this.setState({ allImagesLoadingDone: true });
        increaseUsedStorage(this.state.imageSizes);
        setTimeout(() => {
          this.props.location.state.stepIndex
            ? this.props.finishStepAdd(
                Number(this.props.location.state.stepIndex) +
                  this.state.files.length
              )
            : this.props.finishStepAdd(0);
          if (this.props.location.state.justCreated) {
            history.replace(`/tourtle/${this.props.location.state.id}`);
          } else {
            history.goBack();
          }
        }, 3000);
      }
    }
  }

  startTransform = (file, index) => {
    this.uploadImage(file, index);
  };

  requestStepIds = async () => {
    this.props.onGetTourtleId();
    await api.getTourtleId();
    this.props.onGetTourtleIdSuccess();
  };

  uploadImage = async (tempObjFile, index) => {
    try {
      this.props.onUploadImage();
      // this.props.onGetTourtleId();
      const childObjectId = await api.getTourtleId();
      // this.props.onGetTourtleIdSuccess();

      var object = {};
      object.objectId = this.props.location.state.id;
      object.childObjectId = childObjectId.objectId;
      object.image = tempObjFile.resizedDataUrl;
      const response = await this.upload(object, index);
      this.props.onUploadImageSuccess(response);
      if (response.statusCode === 408) {
        this.props.onSnackbarOpening("upload-error");
        return;
      }
      var finalObject = {
        url: response.data.url,
        childObjectId: childObjectId.objectId,
        index: index,
        name: this.state.filesNames[index],
      };
      const progressArray = this.state.filesProgress;
      progressArray[index].uploadingDone = true;
      this.setState({ filesProgress: progressArray });
      this.finalImagesArray.push(finalObject);
      if (this.finalImagesArray.length === this.state.files.length) {
        this.handleSteps();
      }
    } catch (error) {
      this.props.onUploadImageFailure(error);
      console.log(error);
    }
  };

  upload = async (object, index) => {
    const token = await getAccessToken();
    const axios = require("axios");
    axios.defaults.timeout = 60000;
    const url = `${apiUrl}/${mediaPath}/image`;
    let formData = new FormData();
    formData.append("childObjectId", object.childObjectId);
    formData.append("image", object.image);
    formData.append("objectId", object.objectId);
    const config = {
      headers: { "Content-Type": "multipart/form-data", Authorization: token },
      onUploadProgress: function(progressEvent) {
        var localTotalSizeUploaded = this.state.totalSizeUploaded;
        localTotalSizeUploaded[index] = progressEvent.loaded;
        var total = localTotalSizeUploaded.reduce((a, b) => a + b, 0);
        var percentage = (total * 100) / this.state.imageSizes;
        this.setState({
          totalSizeUploaded: localTotalSizeUploaded,
          progress: percentage,
          showDeterminateLoader: percentage >= 100 ? false : true,
        });
      }.bind(this),
      withCredentials: true,
    };
    const res = await axios.post(url, formData, config);
    this.setState({ progress: 0 });
    return res;
  };

  handleSteps = async () => {
    var orderedSteps = _.orderBy(this.finalImagesArray, "name", "asc");
    for (const step in orderedSteps) {
      await this.handleCreateStep(
        orderedSteps[step].url,
        orderedSteps[step].childObjectId,
        step
      );
    }
  };

  handleCreateStep = async (image, stepId, index) => {
    try {
      const progressArray = this.state.filesProgress;
      progressArray[index].creatingStep = true;
      this.setState({ filesProgress: progressArray });

      this.props.onCreateStep();
      var object = {
        skipCleanup: true,
      };
      object.id = stepId;
      if (image !== "") {
        object.image = image;
      }

      if (
        this.props.location.state.stepIndex !== undefined &&
        this.props.location.state.stepIndex !== null
      ) {
        object.position =
          Number(this.props.location.state.stepIndex) + Number(index);
      }

      const response = await api.createStep(
        this.props.location.state.id,
        object
      );

      let positionToPlace = Number(this.props.tourtleSteps.length) + 1;
      if (
        this.props.location.state.stepIndex !== undefined &&
        this.props.location.state.stepIndex !== null
      ) {
        positionToPlace =
          Number(this.props.location.state.stepIndex) + Number(index);
      }

      await this.props.onCreateStepAfterSuccess(response, positionToPlace);
      progressArray[index].creatingStepDone = true;
      this.setState({ filesProgress: progressArray });
    } catch (error) {
      this.props.onCreateStepFailure(error);
    }
  };

  removeImageFunction = async event => {
    const imageSize = this.state.files[event].resizedDataUrl.size;
    const newImagesPreviewUrls = await this.state.imagesPreviewUrls.filter(
      (_, index) => {
        return index !== event;
      }
    );
    const newFiles = await this.state.files.filter((_, index) => {
      return index !== event;
    });

    const newWorkingImagesArray = await this.workingImagesArray.filter(
      (_, index) => {
        return index !== event;
      }
    );

    this.workingImagesArray = newWorkingImagesArray;
    this.setState(
      {
        imagesPreviewUrls: newImagesPreviewUrls,
        files: newFiles,
        imageSizes: this.state.imageSizes - imageSize,
      },
      () => {}
    );
  };

  setDataUrl = () => {
    this.setState({ dataUrl: null });
  };

  calculatePercentages = () => {
    Math.floor(
      ((this.props.usedStorageSpace + this.state.imageSizes) /
        this.props.storageSpace) *
        100
    );
  };

  calculateSize = files => files.reduce((a, b) => a + b, 0);

  _handleImageChange(e) {
    e.preventDefault();

    var files = _.sortBy(Array.from(e.target.files), [
      file => file.name.toLowerCase(),
    ]);
    const localThis = this;
    const stepLimit =
      this.props.location.state.stepCount || this.state.defaultImageLimit;

    files.forEach(file => {
      let reader = new FileReader();
      reader.onloadend = () => {
        var maxWidth = 2000;
        var maxHeight = 2000;

        if (file.type === "image/heic") {
          let heic = file;
          heic2any({
            blob: heic,
            multiple: false,
            toType: "image/jpeg",
            quality: 0.7,
          })
            .then(conversionResult => {
              var url = URL.createObjectURL(conversionResult);

              localThis.workingImagesArray.push(conversionResult.size);
              const sumSize = localThis.calculateSize(
                localThis.workingImagesArray
              );
              localThis.setState({
                files: [...localThis.state.files, conversionResult].slice(
                  0,
                  stepLimit ? stepLimit : 10
                ),
                imagesPreviewUrls: [
                  ...localThis.state.imagesPreviewUrls,
                  url,
                ].slice(0, stepLimit ? stepLimit : 10),
                imageSizes: sumSize,
              });
            })
            .catch(e => {
              console.log(e);
            });
        } else {
          resize(file, maxWidth, maxHeight, function(
            resizedDataUrl,
            finalDataURL
          ) {
            localThis.workingImagesArray.push(resizedDataUrl.size);
            const sumSize = localThis.calculateSize(
              localThis.workingImagesArray
            );

            const tempObjFile = {
              resizedDataUrl: resizedDataUrl,
              name: file.name,
            };

            const tempObjFilePreviewFile = {
              finalDataURL: finalDataURL,
              name: file.name,
            };

            localThis.setState({
              files: [...localThis.state.files, tempObjFile].slice(
                0,
                stepLimit ? stepLimit : 10
              ),
              filesNames: [...localThis.state.filesNames, file.name].slice(
                0,
                stepLimit ? stepLimit : 10
              ),
              imagesPreviewUrls: [
                ...localThis.state.imagesPreviewUrls,
                tempObjFilePreviewFile,
              ].slice(0, stepLimit ? stepLimit : 10),
              imageSizes: sumSize,
            });
          });
        }
      };

      reader.readAsDataURL(file);

      document.getElementById("file-upload").value = "";
    });

    this.checkAmountOfFiles(files);
  }

  checkAmountOfFiles = localFiles => {
    if (localFiles.length === this.state.files.length)
      this.transformImagesIntoSteps();
    else {
      setTimeout(() => {
        this.checkAmountOfFiles(localFiles);
      }, 100);
    }
  };

  onHandleGoBack = () => {
    if (this.state.files.length > 0) {
      this.handleSaveDialogOpen();
    } else {
      this.handleSaveDialogClose();
    }
  };

  cancelAdd = () => {
    this.handleSaveDialogClose();
  };

  handleSaveDialogOpen = () => {
    this.setState({
      showSaveDialog: true,
    });
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });

    this.props.cancelAddingStep(true);

    if (this.props.location.state && this.props.location.state.justCreated) {
      this.handleDeleteJustCreatedTourtle();
    } else {
      this.props.location.state.stepIndex
        ? this.props.finishStepAdd(
            this.props.location.state.stepIndex + this.state.files.length
          )
        : this.props.finishStepAdd(0);
      this.props.history.goBack();
    }
  };

  async handleDeleteJustCreatedTourtle() {
    const {
      onDeleteTourtleItem,
      onDeleteTourtleItemSuccess,
      onDeleteTourtleItemFailure,
      history,
      location,
    } = this.props;

    const tourtleId = location.state.id;

    try {
      onDeleteTourtleItem();
      const res = await api.deleteTourtle(tourtleId);
      onDeleteTourtleItemSuccess(res);
      history.replace({
        pathname: `/addtourtle/`,
        state: {
          justCreated: true,
        },
      });
    } catch (error) {
      onDeleteTourtleItemFailure(error);
    }
  }

  render() {
    const { classes } = this.props;
    const { imagesPreviewUrls } = this.state;
    const stepLimit =
      this.props.location.state.stepCount || this.state.defaultImageLimit;
    return (
      <React.Fragment>
        <CssBaseline />
        <main>
          <TopBar
            left={<IconButton icon={<BackIcon />} />}
            center={<Title title={this.props.tourtleItem.title} />}
            handleLeftButtonClick={this.onHandleGoBack}
          />
          {this.state.loading &&
            !this.props.snackbar.open &&
            this.state.showDeterminateLoader === true && (
              <div className={classes.spinner}>
                <CircularProgress
                  size={100}
                  variant="determinate"
                  value={this.state.progress}
                  thickness={5}
                  className={classes.loadingCircle}
                />
                <Typography className={classes.nonFound}>
                  Uploading images.
                </Typography>
              </div>
            )}
          {this.state.loading &&
            !this.props.snackbar.open &&
            this.state.showDeterminateLoader === false && (
              <div className={classes.spinner}>
                <CircularProgress
                  size={100}
                  thickness={5}
                  className={classes.loadingCircle}
                />
                <Typography className={classes.nonFound}>
                  Creating steps.
                </Typography>
              </div>
            )}
          {/* Hero unit */}
          {!this.state.loading && (
            <div className={classes.heroUnit}>
              <div className={classes.heroContent}>
                <Typography
                  align="center"
                  color="textSecondary"
                  paragraph
                  className={classes.storageText}
                >
                  Storage:{" "}
                  {`${prettyBytes(
                    this.props.usedStorageSpace + this.state.imageSizes
                  )} / ${prettyBytes(
                    this.props.self.subscription.values.storageLimit
                  )}`}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={Math.floor(
                    (this.props.usedStorageSpace /
                      this.props.self.subscription.values.storageLimit) *
                      100
                  )}
                />
                <Typography
                  align="center"
                  color="textSecondary"
                  paragraph
                  className={classes.infoText}
                >
                  You can add up to 10 images at a time.
                </Typography>
                <div className={classes.heroButtons}>
                  <Grid container justify="center">
                    <Grid item>
                      <form onSubmit={this._handleSubmit}>
                        <Button
                          className={classes.actionButton}
                          variant="contained"
                          color="primary"
                          disabled={
                            this.state.imagesPreviewUrls.length === 10 ||
                            this.state.disabledSelectBtn
                          }
                        >
                          <label htmlFor="file-upload">
                            Select multiple images (
                            {stepLimit - imagesPreviewUrls.length})
                          </label>
                        </Button>
                        <input
                          id="file-upload"
                          className={classes.upload}
                          type="file"
                          accept=".heic, image/*"
                          onChange={this._handleImageChange}
                          multiple
                        />
                      </form>
                    </Grid>
                    <Grid item>
                      {/* <Button
                        className={classes.actionButton}
                        disabled={this.state.files.length === 0}
                        variant="outlined"
                        color="primary"
                        onClick={this.transformImagesIntoSteps}
                      >
                        Turn images into steps
                      </Button> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          )}
          {!this.state.loading && (
            <div className={classNames(classes.layout, classes.cardGrid)}>
              {this.state.allImagesLoadingDone && (
                <div className="loading-page">
                  <CircularProgress
                    size={100}
                    thickness={5}
                    className={classes.loadingSpinner}
                  />
                </div>
              )}
              <Grid container justify="center" spacing={40}>
                {_.orderBy(this.state.imagesPreviewUrls, "name", "asc").map(
                  (image, index) => (
                    <Grid
                      item
                      key={index}
                      className={classes.cardContainer}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                    >
                      <Card className={classes.card}>
                        <CardMedia
                          className={classes.cardMedia}
                          image={image.finalDataURL}
                          key={index}
                          alt="previewImg"
                          src={image.finalDataURL}
                          title="Image title"
                        />
                        <CardContent className={classes.cardContent}>
                          <Typography
                            gutterBottom
                            className={classes.storageText}
                            component="h2"
                          >
                            Size:{" "}
                            {prettyBytes(
                              this.state.files[index].resizedDataUrl.size
                            )}
                          </Typography>
                        </CardContent>
                        {this.state.filesProgress[index] && (
                          <div className="loading-overlay">
                            <div className="uploading">
                              <CloudUpload />
                              Uploading
                              {this.state.filesProgress[index].uploading &&
                                !this.state.filesProgress[index]
                                  .uploadingDone && (
                                  <CircularProgress
                                    size={16}
                                    thickness={5}
                                    className={classes.loadingSpinner}
                                  />
                                )}
                              {this.state.filesProgress[index]
                                .uploadingDone && <Done />}
                            </div>
                            {(this.state.filesProgress[index].creatingStep ||
                              this.state.filesProgress[index]
                                .creatingStepDone) && (
                              <div className="creating-step">
                                <Category />
                                Creating step
                                {this.state.filesProgress[index].creatingStep &&
                                  !this.state.filesProgress[index]
                                    .creatingStepDone && (
                                    <CircularProgress
                                      size={16}
                                      thickness={5}
                                      className={classes.loadingSpinner}
                                    />
                                  )}
                                {this.state.filesProgress[index]
                                  .creatingStepDone && <Done />}
                              </div>
                            )}
                          </div>
                        )}
                      </Card>
                    </Grid>
                  )
                )}
              </Grid>
            </div>
          )}
        </main>
        <Dialog
          open={this.state.showSaveDialog}
          onClose={this.cancelAdd}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Save changes to your tourtle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have made changes to your tourtle. Do you want to save them
              before going back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelAdd} className={classes.actionButton}>
              No
            </Button>
            <Button
              onClick={this.transformImagesIntoSteps}
              color="primary"
              autoFocus
              className={classes.actionButton}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tourtleSteps: selectors.getTourtleSteps(state),
  activeTourtleId: selectors.getSelectedTourtleId(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  getStepCounter: selectors.getStepCounter(state),
  usedStorageSpace: selectors.getAlreadyUsedStorageSpace(state),
  storageSpace: selectors.getAllStorageSpace(state),
  snackbar: selectors.getSnackbar(state),
  tourtleItem: selectors.getTourtleItem(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onFetchTourtleItem: actions.tourtleItemRequest,
  onFetchTourtleItemFailure: actions.tourtleItemFailure,
  onFetchTourtleItemSuccess: actions.tourtleItemSuccess,

  onUploadImage: actions.uploadImageRequest,
  onUploadImageFailure: actions.uploadImageFailure,
  onUploadImageSuccess: actions.uploadImageSuccess,

  onCreateStepFailure: actions.createStepFailure,
  onCreateStepAfterSuccess: actions.createStepAfterSuccess,
  onCreateStep: actions.createStepRequest,

  finishStepAdd: actions.finishStepAdd,

  onSetActiveTourtleId: actions.setActiveTourtleId,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  addRef: actions.createRefOnAddItem,
  cancelAddingStep: actions.cancelAddingStep,
  onSnackbarOpening: actions.fireSnackbarOpening,

  increaseUsedStorage: actions.increaseUsedStorage,
  onAddingStepFromCoverPageOn: actions.fireAddingStepFromCoverPageOn,

  updateStorageUsed: actions.updateStorageUsed,

  onDeleteTourtleItem: actions.deleteTourtleRequest,
  onDeleteTourtleItemFailure: actions.deleteTourtleFailure,
  onDeleteTourtleItemSuccess: actions.deleteTourtleSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadMultipleImages))
);
