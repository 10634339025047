import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import actions from "../../ducks/actions";
import api from "../../api";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import ChipInput from "material-ui-chip-input";

import isArray from "lodash/isArray";

var suggestions = [];

function renderInput(inputProps) {
  const {
    classes,
    autoFocus,
    value,
    onChange,
    onAdd,
    onDelete,
    chips,
    ref,
    ...other
  } = inputProps;

  return (
    <ChipInput
      className={classes.chipInput}
      classes={classes}
      clearInputValueOnChange
      onUpdateInput={onChange}
      onAdd={onAdd}
      onDelete={onDelete}
      value={chips}
      inputRef={ref}
      {...other}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      onMouseDown={e => e.preventDefault()}
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          );
        })}
        <span style={{ fontStyle: "italic" }}>
          {suggestion.info ? ` (${suggestion.info})` : ``}
        </span>
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

async function getSuggestions(value) {
  try {
    const response = await api.searchUsers(value);
    suggestions = [];
    response.forEach(user => {
      let info = "";
      if (user.lastName && user.firstName) {
        info = user.firstName + " " + user.lastName;
      } else if (user.lastName) {
        info = user.lastName;
      } else if (user.firstName) {
        info = user.firstName;
      } else if (user.company) {
        info = user.company;
      }
      var object = {
        name: user.username,
        id: user.id,
        info,
      };
      suggestions.push(object);
    });

    return suggestions;
  } catch (error) {
    return [];
  }
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative",
    marginBottom: "3rem",
    maxHeight: "200px",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 100,
    maxHeight: "130px",
    overflow: "scroll",
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  button: {
    textTransform: "none",
  },
  dialogPaper: {
    margin: "20px",
  },
  secondaryTitle: {
    fontSize: "1.15rem",
    marginBottom: "5px",
  },
  topPadding10: {
    paddingTop: "10px",
  },
  chipInput: {
    width: "100%",
  },
  helperText: {
    marginBottom: -25,
  },
  bottomPadding100: {
    paddingBottom: "20px",
  },
});

class AddCollaboratorDialog extends Component {
  state = {
    value: [],
    sendValue: [],
    textFieldInput: "",
    viewersValue: [],
    viewersSendValue: [],
    viewersTextFieldInput: "",
    suggestions: [],
    sendOptionalMessage: "",
  };

  handleClose = successfulResponse => {
    const { onHandleMemberDialogClose } = this.props;
    onHandleMemberDialogClose(
      isArray(successfulResponse) ? successfulResponse : []
    );
    this.setState({ sendOptionalMessage: "" });
  };

  handleInviteUsers = async () => {
    const {
      tourtleId,
      onAddCollaborators,
      onAddCollaboratorsSuccess,
      onAddCollaboratorsFailure,
    } = this.props;

    var collaboratorsObject = {
      ids: this.state.sendValue,
      viewer_ids: this.state.viewersSendValue,
      message: this.state.sendOptionalMessage,
    };

    try {
      onAddCollaborators();
      const response = await api.addCollaborators(
        tourtleId,
        collaboratorsObject
      );
      await onAddCollaboratorsSuccess(tourtleId, response);
      this.handleClose(this.state.value);
    } catch (error) {
      onAddCollaboratorsFailure(error);
    }
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    const { groupMembers } = this.props;
    const suggestionsLocal = await getSuggestions(value);
    const memberIds = groupMembers.map(x => x.id);
    const filteredSuggestionList = suggestionsLocal.filter(user => {
      return !memberIds.includes(user.id);
    });
    this.setState({
      suggestions: filteredSuggestionList,
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleTextFieldInputChange = (event, { newValue, method }, source = null) => {
    this.setState({
      [source === "viewers"
        ? "viewersTextFieldInput"
        : "textFieldInput"]: newValue,
    });
  };

  onKeyDown = (event, source = null) => {
    let kc = event.which || event.keyCode;

    let valueName = "valueName";
    let sendValueName = "sendValue";
    let textFieldInputName = "textFieldInput";

    if (source === "viewers") {
      valueName = "viewersValue";
      sendValueName = "viewersSendValue";
      textFieldInputName = "viewersTextFieldInput";
    }

    const inputText = this.state[textFieldInputName];

    if (this.validateEmail(inputText)) return;

    if (kc === 13) {
      if (this.validateEmail(inputText)) {
        this.setState({
          [valueName]: [...this.state.value, inputText],
          [sendValueName]: [...this.state.sendValue, inputText],
          [textFieldInputName]: "",
        });
        event.preventDefault();
      } else if (
        this.state.suggestions.filter(
          item => item.name.toLowerCase() === inputText.toLowerCase()
        ).length > 0
      ) {
        let suggestion = this.state.suggestions.filter(
          item => item.name.toLowerCase() === inputText.toLowerCase()
        )[0];
        this.setState({
          [valueName]: [...this.state.value, suggestion.name],
          [sendValueName]: [...this.state.sendValue, suggestion.id],
          [textFieldInputName]: "",
        });
      } else {
        this.setState({
          [textFieldInputName]: inputText,
        });
      }
    } else if (kc === 229) {
      let ss = event.target.value.length - 1;
      let ssv = ss || 0;
      let char = event.target.value.substr(ssv, 1);
      kc = char.charCodeAt(0);
      switch (kc) {
        case 44:
        case 59:
          if (this.validateEmail(inputText.slice(0, -1))) {
            this.setState({
              [valueName]: [...this.state.value, inputText.slice(0, -1)],
              [sendValueName]: [
                ...this.state.sendValue,
                inputText.slice(0, -1),
              ],
              [textFieldInputName]: "",
            });
            event.preventDefault();
          } else if (
            this.state.suggestions.filter(
              item =>
                item.name.toLowerCase() === inputText.slice(0, -1).toLowerCase()
            ).length > 0
          ) {
            let suggestion = this.state.suggestions.filter(
              item =>
                item.name.toLowerCase() === inputText.slice(0, -1).toLowerCase()
            )[0];
            this.setState({
              [valueName]: [...this.state.value, suggestion.name],
              [sendValueName]: [...this.state.sendValue, suggestion.id],
              [textFieldInputName]: "",
            });
          } else {
            this.setState({
              [textFieldInputName]: inputText.slice(0, -1),
            });
          }
          break;
        default:
          return;
      }
    } else {
      switch (kc) {
        case 13:
        case 188:
        case 186:
          if (this.validateEmail(inputText.slice(0, -1))) {
            this.setState({
              [valueName]: [...this.state.value, inputText.slice(0, -1)],
              [sendValueName]: [
                ...this.state.sendValue,
                inputText.slice(0, -1),
              ],
              [textFieldInputName]: "",
            });
            event.preventDefault();
          } else if (
            this.state.suggestions.filter(
              item =>
                item.name.toLowerCase() === inputText.slice(0, -1).toLowerCase()
            ).length > 0
          ) {
            let suggestion = this.state.suggestions.filter(
              item =>
                item.name.toLowerCase() === inputText.slice(0, -1).toLowerCase()
            )[0];
            this.setState({
              [valueName]: [...this.state.value, suggestion.name],
              [sendValueName]: [...this.state.sendValue, suggestion.id],
              [textFieldInputName]: "",
            });
          } else {
            this.setState({
              [textFieldInputName]: inputText.slice(0, -1),
            });
          }
          break;
        default:
          return;
      }
    }
  };

  validateEmail = value => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  };

  handleAddChip(chip, source = null) {
    var chipId = this.getChipId(chip);
    if (source === "viewers") {
      this.setState({
        viewersValue: [...this.state.viewersValue, chip],
        viewersSendValue: [...this.state.viewersSendValue, chipId],
        viewersTextFieldInput: "",
      });
    } else {
      this.setState({
        value: [...this.state.value, chip],
        sendValue: [...this.state.sendValue, chipId],
        textFieldInput: "",
      });
    }
  }

  handleDeleteChip(chip, index, source = null) {
    if (source === "viewers") {
      let temp = this.state.viewersValue;
      let tempSend = this.state.viewersSendValue;
      temp.splice(index, 1);
      tempSend.splice(index, 1);

      this.setState({
        viewersValue: temp,
        viewersSendValue: tempSend,
      });
    } else {
      let temp = this.state.value;
      let tempSend = this.state.sendValue;
      temp.splice(index, 1);
      tempSend.splice(index, 1);

      this.setState({
        value: temp,
        sendValue: tempSend,
      });
    }
  }

  getChipId(chip) {
    var id = "";
    this.state.suggestions.forEach(suggestion => {
      if (suggestion.name === chip) {
        id = suggestion.id;
      }
    });
    return id;
  }

  handleMessageFieldInputChange = value => {
    this.setState({ sendOptionalMessage: value.target.value });
  };

  render() {
    const { classes, open } = this.props;
    const { value, suggestions } = this.state;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          fullWidth={true}
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <DialogContent className={classes.bottomPadding100}>
            <Typography variant="h6" className={classes.secondaryTitle}>
              {this.props.isMine
                ? "Add Collaborators"
                : "Add Collaborators/Viewers"}
            </Typography>
            {this.props.isMine && (
              <div>
                <Autosuggest
                  className={classes.autosuggestContainer}
                  theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                  renderInputComponent={renderInput}
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={
                    this.handleSuggestionsFetchRequested
                  }
                  onSuggestionsClearRequested={
                    this.handleSuggestionsClearRequested
                  }
                  renderSuggestionsContainer={renderSuggestionsContainer}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  onSuggestionSelected={(e, { suggestionValue }) => {
                    if (suggestionValue === "enter") {
                    }
                    this.handleAddChip(suggestionValue);
                    e.preventDefault();
                  }}
                  inputProps={{
                    placeholder: "Enter user’s first, last, or username",
                    helperText: (
                      <span>
                        Enter <strong>name</strong> and select from the
                        <strong> list</strong>.
                      </span>
                    ),
                    classes,
                    chips: value,
                    onChange: this.handleTextFieldInputChange,
                    onKeyUp: this.onKeyDown,
                    value: this.state.textFieldInput,
                    onDelete: (chip, index) =>
                      this.handleDeleteChip(chip, index),
                  }}
                />

                <TextField
                  margin="normal"
                  id="message"
                  label="Message to include in the invitation"
                  placeholder="Message"
                  variant="outlined"
                  fullWidth
                  className="inviteMessage"
                  onChange={this.handleMessageFieldInputChange}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={3}
                ></TextField>
              </div>
            )}
            {!this.props.isMine && (
              <div>
                <Typography>
                  Collaborators and/or Viewers can only be added by the author
                  of this tourtle.
                </Typography>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleInviteUsers}
              color="primary"
              disabled={
                !this.props.isMine ||
                (this.state.value.length < 1 &&
                  this.state.viewersValue.length < 1)
              }
            >
              <div>Add</div>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  onAddCollaborators: actions.addCollaboratorsRequest,
  onAddCollaboratorsFailure: actions.addCollaboratorsFailure,
  onAddCollaboratorsSuccess: actions.addCollaboratorsSuccess,

  onHandleMemberDialogClose: actions.fireAddMemberMenuClosing,
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(AddCollaboratorDialog)
  )
);
