import React from "react";
import { withRouter } from "react-router-dom";
import actions from "../../ducks/actions";
import selectors from "../../ducks/selectors";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import customDraftJsEntityTransform from "../../utils/draftJs";
import linkifyHtml from "linkifyjs/html";
import moment from "moment";

import api from "../../api";
import Cookies from "js-cookie";
import { keys } from "lodash";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import { ListItem, Tooltip } from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./LandingPage.css";

import Logo from "../common/Logo/Logo";
import ImgHeader from "../Utils/Images/cover.png";
import Img1 from "../Utils/Images/page2_1.png";
import Img2 from "../Utils/Images/page2_2.png";
import Img3 from "../Utils/Images/page2_3.png";
import Img4 from "../Utils/Images/page2_4.png";
import Img1_2 from "../Utils/Images/page3_1.png";
import Img2_2 from "../Utils/Images/page3_d.png";
import Img3_2 from "../Utils/Images/page3_3.png";
import Img2_4 from "../Utils/Images/page3_4.png";
import Privacy from "../Utils/Images/page3_2.png";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import LandingPageTourtleItem from "./LandingPageTourtleItem";
import VideoPlayer from "../common/VideoPlayer";

const handleDragStart = e => e.preventDefault();

class NewLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
    this.carousel1Ref = React.createRef();
    this.carousel2Ref = React.createRef();
    this.observerCarousel1Ref = IntersectionObserver;
    this.observerCarousel2Ref = IntersectionObserver;
    this.carouselTimer1 = null;
    this.carouselTimer2 = null;
  }

  params = this.props.match.params;

  state = {
    selectedSection1: 0,
    selectedSection2: 0,
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    exampleTourtles: [],
    faq: [],
    faqTourtleUrl: null,
    configurationTourtle: {},
    configurationNormalTourtle: {},
    configurationCategoryTourtle: {},
    configurationTourtleSettings: {},
    firstTimeConfigurationTourtleSettingsSet: false,
    items: [],
    items2: [],
    showExamples: false,
  };

  componentDidMount() {
    this.setState({ items: this.items });
    this.setState({ items2: this.items2 });
    this.fetchConfigurationTourtle();
    this.autoPlayCarousel1();
    this.autoPlayCarousel2();
    this.showCookies();
  }

  componentDidUpdate() {
    if (
      Object.keys(this.state.configurationTourtleSettings).length > 0 &&
      !this.state.firstTimeConfigurationTourtleSettingsSet
    ) {
      // First time content is loaded
      this.setCarouselItems();
      const faqTourtleUrl = this.state.configurationTourtleSettings["FAQs"];
      const parsedUrl = new URL(faqTourtleUrl);
      const path = parsedUrl.pathname;
      this.setState({ faqTourtleUrl: path });
      const pathWithFirstPartAsId = path.replace("/tourtle/", "").split("/");
      const faqTourtleId = pathWithFirstPartAsId[0];

      this.fetchFaqTourtle(faqTourtleId);
      this.setState({ firstTimeConfigurationTourtleSettingsSet: true });
    }
  }

  showCookies = async () => {
    let cookies = await Cookies.getJSON();

    if (!keys(cookies).includes("CONSENT")) {
      this.props.cookieDialogOpening(); // Remove if cookie dialog needs to be shown on any page
    }
  };

  fetchExampleTourtles = async tourtleId => {
    const response = await api.fetchExampleTourtles(tourtleId);
    this.setState({
      exampleTourtles: response || [],
    });
  };

  fetchConfigurationTourtle = async tourtleId => {
    const response = await api.fetchConfigurationTourtle(tourtleId);

    this.setState({
      configurationTourtle: response || {},
    });

    this.fetchConfigurationCategoryTourtle(response.steps);
  };

  fetchConfigurationCategoryTourtle = async steps => {
    const categoryName = this.params.configurationName;
    const tourtleIdNormal = this.getTourtleIdBasedOnTitle("normal", steps);

    const tourtleIdCategory = this.getTourtleIdBasedOnTitle(
      categoryName,
      steps
    );

    const responseNormal = api.fetchConfigurationTourtle(tourtleIdNormal);
    const responses = [];

    responses.push(responseNormal);

    if (tourtleIdCategory) {
      const responseCategory = api.fetchConfigurationTourtle(tourtleIdCategory);
      responses.push(responseCategory);
    }

    const responsesDone = await Promise.all(responses);

    this.setState({
      configurationNormalTourtle: responsesDone[0] || {},
      configurationCategoryTourtle: responsesDone[1] || {},
    });
    if (responsesDone[1]) {
      this.setPageContentBasedOnCategory(responsesDone[0], responsesDone[1]);
    } else {
      this.setPageContentBasedOnCategory(responsesDone[0]);
    }
  };

  setPageContentBasedOnCategory = (normalTourtle, categoryTourtle) => {
    const obj = {};

    normalTourtle.steps.forEach(step => {
      obj[step.title] = this.getContentFromStep(step);
    });

    if (categoryTourtle?.steps) {
      categoryTourtle.steps.forEach(step => {
        obj[step.title] = this.getContentFromStep(step);
      });
    }

    obj.examples = categoryTourtle
      ? categoryTourtle.examples
      : normalTourtle.examples;

    this.setState({ configurationTourtleSettings: obj });
  };

  fetchFaqTourtle = async customId => {
    let id =
      customId ??
      (window.location.host.includes("staging") ||
      window.location.host.includes("test") ||
      window.location.host.includes("localhost") ||
      window.location.host.includes("192.168.1")
        ? "6349568da1d2f76ed3aba0fc"
        : "5d1e5b7d257b1a2f3e9b0ab6");

    const response = await api.fetchTourtleItem(id);

    this.setState({ faq: response.steps });
  };

  getTourtleIdBasedOnTitle = (categoryName, steps) => {
    const categoryStep = steps.find(
      step => step.title.toLowerCase() === categoryName
    );

    if (!categoryStep) {
      return undefined;
    }

    const url = categoryStep.description;
    const parsedUrl = new URL(url);
    const tourtleId = parsedUrl.pathname
      .replace("/tourtle/", "")
      .replace(`/${categoryName}`, "");
    return tourtleId;
  };

  getContentFromStep(step) {
    if (step.image) {
      const parsedDescription = JSON.parse(step.description);
      if (parsedDescription.blocks.length > 0) {
        const descriptionTitle = parsedDescription.blocks.find(block =>
          block.type.startsWith("header-")
        );
        const descriptionContent = parsedDescription.blocks.filter(
          block => !block.type.startsWith("header-")
        );
        const descriptionContentObj = {
          blocks: descriptionContent,
        };

        const obj = {
          img: step.image,
        };

        if (descriptionTitle) {
          obj.title = descriptionTitle.text;
        }

        obj.content =
          step.title === "Privacy image"
            ? linkifyHtml(draftToHtml(descriptionContentObj))
            : this.decodeDescription(descriptionContentObj);

        return obj;
      }

      return step.image;
    }

    if (step.video) {
      return step.video;
    }

    const parsedDescription = JSON.parse(step.description);
    const decodedDescription =
      step.title === "Privacy image"
        ? linkifyHtml(draftToHtml(parsedDescription))
        : this.decodeDescription(parsedDescription);
    return decodedDescription;
  }

  decodeDescription(stepDescription) {
    const text = linkifyHtml(
      draftToHtml(stepDescription, null, null, customDraftJsEntityTransform)
    );

    const replacedText = text.replace(
      /<(?!\s*\/?(strong|em|h1|h2|h3|h4)\b)[^>]+>/gi,
      ""
    );
    return replacedText;
  }

  goToHome = () => {
    const { history } = this.props;
    history.push({ pathname: "/library/mine" });
    window.scrollTo(0, 0);
  };

  goToSignIn = () => {
    const { history } = this.props;
    history.push({ pathname: "/sign-in-up/login" });
  };

  goToSignUp = () => {
    const { history } = this.props;
    history.push({ pathname: "/sign-in-up/choose-name" });
  };

  goToFaq = () => {
    const { history } = this.props;
    console.log(this.state.faqTourtleUrl);
    history.push({
      pathname:
        this.state.faqTourtleUrl != null &&
        this.state.faqTourtleUrl !== undefined &&
        this.state.faqTourtleUrl !== ""
          ? this.state.faqTourtleUrl
          : window.location.host.includes("staging") ||
            window.location.host.includes("test") ||
            window.location.host.includes("localhost") ||
            window.location.host.includes("192.168.1")
          ? "/tourtle/6349568da1d2f76ed3aba0fc/tourtle-faqs"
          : "/tourtle/5d1e5b7d257b1a2f3e9b0ab6/tourtle-faqs",
    });
  };

  gotoAbout = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e876a1d2f74659aba023/tourtle-corporate-information"
          : "/tourtle/5d363e89141fc957f7f8d889/tourtle-corporate-information",
    });
  };

  gotoHelp = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335e648a1d2f74a2eaba01e/tourtle-help"
          : "/tourtle/6324ced1bc206b381fe4b6da/tourtle-help-and-guidelines",
    });
  };

  gotoPolicies = () => {
    const { history } = this.props;
    history.push({
      pathname:
        window.location.host.includes("staging") ||
        window.location.host.includes("test") ||
        window.location.host.includes("localhost") ||
        window.location.host.includes("192.168.1")
          ? "/tourtle/6335c021a1d2f76caeab9fec/policies"
          : "/tourtle/632ceb4fbc206b9de3e4b7fb/policies",
    });
  };

  slideChangedSection1 = event => {
    this.setState({ selectedSection1: event.slide });
  };

  slideChangedSection2 = event => {
    this.setState({ selectedSection2: event.slide });
  };

  toggleFaq = faqItem => {
    if (faqItem === 1) {
      this.setState({ faq1: !this.state.faq1 });
    } else if (faqItem === 2) {
      this.setState({ faq2: !this.state.faq2 });
    } else if (faqItem === 3) {
      this.setState({ faq3: !this.state.faq3 });
    } else {
      this.setState({ faq4: !this.state.faq4 });
    }
  };

  setCarousel1 = item => {
    this.cancelAutoPlayCarousel1();
    this.setState({ selectedSection1: item });
  };

  setCarousel2 = item => {
    this.cancelAutoPlayCarousel2();
    this.setState({ selectedSection2: item });
  };

  autoPlayCarousel1 = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.timerCarousel1 = setInterval(this.switchImageCarousel1, 3000);
        } else {
          this.cancelAutoPlayCarousel1();
        }
      },
      { threshold: 0.7 }
    );

    observer.observe(this.carousel1Ref.current);
    this.observerCarousel1Ref = observer;
  };

  cancelAutoPlayCarousel1 = item => {
    clearInterval(this.timerCarousel1);
  };

  switchImageCarousel1 = () => {
    this.setState({
      selectedSection1:
        this.state.selectedSection1 < 3 ? this.state.selectedSection1 + 1 : 0,
    });
  };

  autoPlayCarousel2 = () => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.timerCarousel2 = setInterval(this.switchImageCarousel2, 3000);
        } else {
          this.cancelAutoPlayCarousel2();
        }
      },
      { threshold: 0.7 }
    );

    observer.observe(this.carousel2Ref.current);
    this.observerCarousel2Ref = observer;
  };

  cancelAutoPlayCarousel2 = item => {
    clearInterval(this.timerCarousel2);
  };

  switchImageCarousel2 = () => {
    this.setState({
      selectedSection2:
        this.state.selectedSection2 < 3 ? this.state.selectedSection2 + 1 : 0,
    });
  };

  componentWillUnmount() {
    if (this.observerCarousel1Ref) {
      this.observerCarousel1Ref.disconnect();
    }
    if (this.observerCarousel2Ref) {
      this.observerCarousel2Ref.disconnect();
    }
  }

  setCarouselItems() {
    const items = [
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Section 4 Image 1"]?.img ??
            Img4
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image-sticky"
          alt="1"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Section 4 Image 2"]?.img ??
            Img1
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="2"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Section 4 Image 3"]?.img ??
            Img2
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="3"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Section 4 Image 4"]?.img ??
            Img3
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="4"
        />
      </div>,
    ];

    const items2 = [
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Sharing Image 1"]?.img ??
            Img1_2
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="1"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Sharing Image 2"]?.img ??
            Img2_4
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="2"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Sharing Image 3"]?.img ??
            Img3_2
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="3"
        />
      </div>,
      <div className="carousel-image-container">
        <img
          src={
            this.state.configurationTourtleSettings["Sharing Image 4"]?.img ??
            Img2_2
          }
          onDragStart={handleDragStart}
          role="presentation"
          className="carousel-image"
          alt="4"
        />
      </div>,
    ];
    this.setState({ items: items });
    this.setState({ items2: items2 });
  }

  render() {
    return (
      <div className="landing-page">
        <AppBar position="static" className="app-bar">
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
              <Toolbar
                className="toolbar"
                onClick={this.props.userInfo ? this.goToHome : () => {}}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip
                    title={this.props.userInfo ? "Return to tourtle" : ""}
                  >
                    <div>
                      <Logo
                        landing={true}
                        userInfo={this.props.userInfo}
                        showSkeleton={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length === 0
                        }
                        customLogo={
                          this.state.configurationTourtleSettings["Logo"]?.img
                        }
                      />
                      {this.state.configurationTourtleSettings["Tagline"] && (
                        <Typography
                          variant="h1"
                          className={
                            Object.keys(this.state.configurationTourtleSettings)
                              .length > 0
                              ? "tagline skeleton skeleton-loaded"
                              : "tagline skeleton"
                          }
                        >
                          {this.state.configurationTourtleSettings["Tagline"] ??
                            "Your stuff, organized"}
                        </Typography>
                      )}
                    </div>
                  </Tooltip>
                </div>
              </Toolbar>
            </Grid>
          </Grid>
        </AppBar>

        {/* HEADER */}
        <Grid container justify="center" className="grid-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  <Typography
                    variant="h3"
                    className={
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "title-one skeleton skeleton-loaded"
                        : "title-one skeleton"
                    }
                  >
                    {this.state.configurationTourtleSettings[
                      "Opening Heading"
                    ] ?? "Keep Everything in One Place."}
                  </Typography>
                  <Typography
                    variant="h3"
                    gutterBottom
                    className="subtitle-one"
                  >
                    <span
                      className={
                        Object.keys(this.state.configurationTourtleSettings)
                          .length > 0
                          ? "skeleton skeleton-loaded"
                          : "skeleton"
                      }
                    >
                      {this.state.configurationTourtleSettings[
                        "Opening Subheading A"
                      ] ?? "Share only what you want,"}
                    </span>
                    <br />
                    <span
                      className={
                        Object.keys(this.state.configurationTourtleSettings)
                          .length > 0
                          ? "skeleton skeleton-loaded"
                          : "skeleton"
                      }
                    >
                      {this.state.configurationTourtleSettings[
                        "Opening Subheading B"
                      ] ?? "With only the people you want."}
                    </span>
                  </Typography>
                  {!this.props.userInfo && (
                    <div className="sign-up-button-container">
                      <Button
                        variant="contained"
                        color="primary"
                        aria-label="options"
                        className="sign-up-button"
                        onClick={this.goToSignUp}
                      >
                        Sign up for free
                      </Button>
                    </div>
                  )}
                  {!this.props.userInfo && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className="already-have-account"
                    >
                      Already have an account?{" "}
                      <span
                        onClick={this.goToSignIn}
                        className="sign-in-button"
                      >
                        Sign in
                      </span>
                    </Typography>
                  )}
                  {this.props.userInfo && (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className="already-signed-in"
                    >
                      (Click the logo above to toggle back to tourtle.)
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item header-grid-item">
                  <div
                    className={`header-image-container ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <img
                      className="header-image"
                      src={
                        this.state.configurationTourtleSettings[
                          "Section 1 Image 1"
                        ]?.img ?? ImgHeader
                      }
                      alt="Header"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey extra-bottom-padding"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className={
                Object.keys(this.state.configurationTourtleSettings).length > 0
                  ? "title-one skeleton skeleton-loaded"
                  : "title-one skeleton"
              }
              align="center"
            >
              {this.state.configurationTourtleSettings["Section 2 Heading"] ??
                "Track, plan, and organize anything."}
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              className="subtitle"
            >
              A tourtle is a <i>supercharged</i> list.
              <br /> Add images to help you find tourtles easily.{" "}
              <span style={{ color: "#F95700" }}>
                Click on examples below.{" "}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <div className="segment grey extra-bottom-padding example-tourtles-container">
          <Grid
            container
            justify="center"
            className="grid-container background"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              className="example-tourtles"
            >
              {/* TOP CONTAINER */}
              <Grid container justify="center">
                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[0] &&
                  this.state.configurationTourtleSettings[
                    "Example 1 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 1 Heading"
                              ] ?? "tourtles you <strong>keep Private</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[0][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[0][0]
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[0][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[0][1]
                          }
                        />
                      )}
                    </Grid>
                  )}

                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[1] &&
                  this.state.configurationTourtleSettings[
                    "Example 2 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 2 Heading"
                              ] ??
                              "tourtles you share with <strong>your family</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[1][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[1][0]
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[1][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[1][1]
                          }
                        />
                      )}
                    </Grid>
                  )}
                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[2] &&
                  this.state.configurationTourtleSettings[
                    "Example 3 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 3 Heading"
                              ] ??
                              "tourtles you share with <strong>your friends</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[2][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[2][0]
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[2][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[2][1]
                          }
                        />
                      )}
                    </Grid>
                  )}
              </Grid>
              {/* BOTTOM CONTAINER */}
              <Grid container justify="center">
                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[3] &&
                  this.state.configurationTourtleSettings[
                    "Example 4 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 4 Heading"
                              ] ??
                              "tourtles you share with <strong>your club</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[3][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[3][0]
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[3][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[3][1]
                          }
                        />
                      )}
                    </Grid>
                  )}

                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[4] &&
                  this.state.configurationTourtleSettings[
                    "Example 5 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 5 Heading"
                              ] ??
                              "tourtles you share with <strong>your customers</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[4][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings
                              .examples[4][0]
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[4][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings?.examples &&
                            this.state.configurationTourtleSettings.examples[4]
                              ? this.state.configurationTourtleSettings
                                  .examples[4][1]
                              : null
                          }
                        />
                      )}
                    </Grid>
                  )}

                {this.state.configurationTourtleSettings?.examples &&
                  this.state.configurationTourtleSettings.examples[5] &&
                  this.state.configurationTourtleSettings[
                    "Example 6 Heading"
                  ]?.replace(" ", "").length > 1 && (
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Typography
                        variant="h6"
                        className={
                          Object.keys(this.state.configurationTourtleSettings)
                            .length > 0
                            ? "example-tourtles__title skeleton skeleton-loaded"
                            : "example-tourtles__title skeleton"
                        }
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              this.state.configurationTourtleSettings[
                                "Example 6 Heading"
                              ] ??
                              "tourtles you publish to <strong>the world</strong>",
                          }}
                        ></span>
                      </Typography>
                      {this.state.configurationTourtleSettings
                        .examples[5][0] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings?.examples &&
                            this.state.configurationTourtleSettings.examples[5]
                              ? this.state.configurationTourtleSettings
                                  .examples[5][0]
                              : null
                          }
                        />
                      )}
                      {this.state.configurationTourtleSettings
                        .examples[5][1] && (
                        <LandingPageTourtleItem
                          tourtle={
                            this.state.configurationTourtleSettings?.examples &&
                            this.state.configurationTourtleSettings.examples[5]
                              ? this.state.configurationTourtleSettings
                                  .examples[5][1]
                              : null
                          }
                        />
                      )}
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="grid-container segment segment__title pb-4"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6} className="">
                <Typography
                  gutterBottom
                  variant="h3"
                  className="title-one"
                  align="center"
                >
                  See how easy it is
                  <br />
                  to make a tourtle.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="video-player-placeholder">
                  {Object.keys(this.state.configurationTourtleSettings)
                    .length === 0 && <div className="skeleton"></div>}
                  {Object.keys(this.state.configurationTourtleSettings).length >
                    0 && (
                    <VideoPlayer
                      url={this.state.configurationTourtleSettings["Video"]}
                      className="video-player"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Make a list of stuff
              <br />
              (aka a "tourtle")
            </Typography>
            <Typography
              gutterBottom
              align="center"
              variant="body1"
              className="subtitle"
            >
              Besides text,{" "}
              <i>
                <b>each item</b>
              </i>{" "}
              in a tourtle list can have photos, videos, audio, attachments,
              links, even other tourtles. Collaborate on tourtles with anyone.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__columns grid-container grey"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div
                  ref={this.carousel1Ref}
                  className="grid-item carousel"
                  onClick={this.cancelAutoPlayCarousel1}
                >
                  {Object.keys(this.state.configurationTourtleSettings).length >
                    0 && (
                    <AliceCarousel
                      items={this.state.items}
                      activeIndex={this.state.selectedSection1}
                      disableButtonsControls={true}
                      onSlideChanged={this.slideChangedSection1}
                      mouseTracking
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="carousel-text-web">
                <div className="grid-item">
                  <div
                    className={`item-container ${
                      this.state.selectedSection1 === 0 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection1 === 0 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel1(0)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 1"
                            ]?.title ?? "A typical list vs a tourtle list.",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection1 === 0 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 1"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection1 === 1 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection1 === 1 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel1(1)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 2"
                            ]?.title ?? "Text and photos,",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection1 === 1 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 2"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection1 === 2 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection1 === 2 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel1(2)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 3"
                            ]?.title ?? "videos and audio clips,",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection1 === 2 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 3"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection1 === 3 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection1 === 3 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel1(3)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 4"
                            ]?.title ?? "file attachments and links.",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection1 === 3 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 4"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__rows grid-container grey"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 1"
                            ]?.title ?? "file attachments and links.",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 1"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items?.length > 0 ? this.state.items[0] : ""}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 2"
                            ]?.title ?? "file attachments and links.",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 2"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items?.length > 0 ? this.state.items[1] : ""}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 3"
                            ]?.title ?? "file attachments and links.",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 3"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items?.length > 0 ? this.state.items[2] : ""}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 4"
                            ]?.title ?? "file attachments and links.",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Section 4 Image 4"
                            ]?.content ?? "",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items?.length > 0 ? this.state.items[3] : ""}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid container justify="center" className="segment segment__title">
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Selectively share
              <br />
              ...or not.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__columns grid-container"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={8}
            className="carousel-container"
          >
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6} className="carousel-text-web">
                <div className="grid-item">
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 0 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 0 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(0)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 1"
                            ]?.title ?? "Post a tourtle list to a group",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 0 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 1"
                            ]?.content ??
                            "<i>For their eyes only.</i> Create as many groups as you'd like - family, friends, clubs, teams, ...",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 1 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 1 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(1)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 2"
                            ]?.title ?? "Or share it privately",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 1 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 2"
                            ]?.content ??
                            "Change your tourtle's visibility to UNLISTED and send a link via email or text.",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 2 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 2 ? "selected" : ""
                      }`}
                      onClick={() => this.setCarousel2(2)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 3"
                            ]?.title ?? "Or publish it to the world",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 2 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 3"
                            ]?.content ??
                            "Change your tourtle's visibility to PUBLIC and share it to social media.",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  <div
                    className={`item-container ${
                      this.state.selectedSection2 === 3 ? "selected" : ""
                    } ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography
                      variant="h4"
                      className={`item-title ${
                        this.state.selectedSection2 === 3 ? "selected" : ""
                      } ${
                        Object.keys(this.state.configurationTourtleSettings)
                          .length > 0
                          ? "skeleton skeleton-loaded"
                          : "skeleton"
                      }`}
                      onClick={() => this.setCarousel2(3)}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 4"
                            ]?.title ?? "Or just keep it to yourself.",
                        }}
                      ></span>
                    </Typography>
                    <Typography
                      className={`item-subtext ${
                        this.state.selectedSection2 === 3 ? "selected" : ""
                      }`}
                      variant="body1"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 4"
                            ]?.content ??
                            "Make tourtles simply to track and organize all your own stuff. tourtle is <strong>your own mobile filing cabinet.</strong>",
                        }}
                      ></span>
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div
                  ref={this.carousel2Ref}
                  className="grid-item carousel"
                  onClick={this.cancelAutoPlayCarousel2}
                >
                  {Object.keys(this.state.configurationTourtleSettings).length >
                    0 && (
                    <AliceCarousel
                      items={this.state.items2}
                      activeIndex={this.state.selectedSection2}
                      disableButtonsControls={true}
                      onSlideChanged={this.slideChangedSection2}
                      mouseTracking
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment__rows grid-container"
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 1"
                            ]?.title ?? "Post a tourtle list to a group",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 1"
                            ]?.content ??
                            "<i>For their eyes only.</i> Create as many groups as you'd like - family, friends, clubs, teams, ...",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items2?.length > 0 ? this.state.items2[0] : ""}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 2"
                            ]?.title ?? "Or share it privately",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 2"
                            ]?.content ??
                            "Change your tourtle's visibility to UNLISTED and send a link via email or text.",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items2?.length > 0 ? this.state.items2[1] : ""}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 3"
                            ]?.title ?? "Or publish it to the world",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 3"
                            ]?.content ??
                            "Change your tourtle's visibility to PUBLIC and share it to social media.",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items2?.length > 0 ? this.state.items2[2] : ""}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className="carousel-text-mobile"
              >
                <div className="grid-item text-container">
                  <div
                    className={`item-container-mobile ${
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "skeleton skeleton-loaded"
                        : "skeleton"
                    }`}
                  >
                    <Typography variant="h5" className="text" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 4"
                            ]?.title ?? "Or just keep it to yourself.",
                        }}
                      ></span>
                    </Typography>
                    <Typography variant="body1" align="center">
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            this.state.configurationTourtleSettings[
                              "Sharing Image 4"
                            ]?.content ??
                            "Make tourtles simply to track and organize all your own stuff. tourtle is <strong>your own mobile filing cabinet.</strong>",
                        }}
                      ></span>
                    </Typography>
                  </div>
                  {this.state.items2?.length > 0 ? this.state.items2[3] : ""}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid
          container
          justify="center"
          className="segment segment__title grey"
        >
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Frequently asked questions
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment faq grid-container grey"
        >
          <Grid item xs={12} sm={10} md={6}>
            <ListItem button divider={true} onClick={() => this.toggleFaq(1)}>
              <ListItemText
                primary={this.state.faq[0] ? this.state.faq[0].title : ""}
                classes={{ primary: "faq-title" }}
              />
              {!this.state.faq1 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq1} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[0] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[0].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(2)}>
              <ListItemText
                primary={this.state.faq[1] ? this.state.faq[1].title : ""}
                classes={{ primary: "faq-title" }}
              />
              {!this.state.faq2 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq2} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[1] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[1].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(3)}>
              <ListItemText
                classes={{ primary: "faq-title" }}
                primary={this.state.faq[2] ? this.state.faq[2].title : ""}
              />
              {!this.state.faq3 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq3} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[2] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[2].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>

            <ListItem button divider={true} onClick={() => this.toggleFaq(4)}>
              <ListItemText
                classes={{ primary: "faq-title" }}
                primary={this.state.faq[3] ? this.state.faq[3].title : ""}
              />
              {!this.state.faq4 ? (
                <ExpandMore color="primary" />
              ) : (
                <ExpandLess color="primary" />
              )}
            </ListItem>
            <Collapse in={this.state.faq4} timeout="auto" unmountOnExit>
              <Typography className="faq-description">
                {this.state.faq[3] && (
                  <span
                    className="faq-description-text"
                    dangerouslySetInnerHTML={{
                      __html: linkifyHtml(
                        draftToHtml(
                          JSON.parse(this.state.faq[3].description),
                          null,
                          null,
                          customDraftJsEntityTransform
                        )
                      ),
                    }}
                  />
                )}
              </Typography>
            </Collapse>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          className="segment faq-button extra-bottom-padding grey"
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              aria-label="options"
              onClick={this.goToFaq}
            >
              Other questions
            </Button>
          </Grid>
        </Grid>

        {/* SECTION */}
        <Grid container justify="center" className="segment segment__title ">
          <Grid item xs={10} sm={10} md={6}>
            <Typography
              gutterBottom
              variant="h3"
              className="title-one"
              align="center"
            >
              Privacy by design
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" className="grid-container">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  {this.state.configurationTourtleSettings["Privacy image"]
                    ?.content !== null &&
                    this.state.configurationTourtleSettings["Privacy image"]
                      ?.content !== undefined && (
                      <Typography
                        variant="body1"
                        className="privacy-subtitle-desktop"
                      >
                        <span
                          className="privacy-description-text"
                          dangerouslySetInnerHTML={{
                            __html: this.state.configurationTourtleSettings[
                              "Privacy image"
                            ].content.replace("\n", ""),
                          }}
                        />
                      </Typography>
                    )}
                  {(this.state.configurationTourtleSettings["Privacy image"]
                    ?.content === null ||
                    this.state.configurationTourtleSettings["Privacy image"]
                      ?.content === undefined) && (
                    <Typography
                      variant="body1"
                      className="privacy-subtitle-desktop"
                    >
                      Every tourtle you create is <strong>Private</strong> by
                      default.
                      <br />
                      Your data is <strong>secure</strong>,<br />
                      and <strong>not shared with third parties.</strong>
                      <br />
                      <br />
                      It's{" "}
                      <strong>
                        <i>your</i>
                      </strong>{" "}
                      data.
                      <br />
                      <strong>
                        <i>You</i>
                      </strong>{" "}
                      decide what to share
                      <br /> and with whom.
                    </Typography>
                  )}
                  {(this.state.configurationTourtleSettings["Privacy image"]
                    ?.content === null ||
                    this.state.configurationTourtleSettings["Privacy image"]
                      ?.content === undefined) && (
                    <Typography
                      variant="body1"
                      className="privacy-subtitle-mobile"
                    >
                      Every tourtle you create is <strong>Private</strong> by
                      default. Your data is <strong>secure</strong>, and{" "}
                      <strong>not shared with third parties.</strong>
                      <br />
                      <br />
                      It's{" "}
                      <strong>
                        <i>your</i>
                      </strong>{" "}
                      data.
                      <strong>
                        <i>You</i>
                      </strong>{" "}
                      decide what to share and with whom.
                    </Typography>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="grid-item">
                  <div
                    className={
                      Object.keys(this.state.configurationTourtleSettings)
                        .length > 0
                        ? "privacy-image-container skeleton skeleton-loaded"
                        : "privacy-image-container skeleton"
                    }
                  >
                    <img
                      className="privacy-image"
                      src={
                        this.state.configurationTourtleSettings["Privacy image"]
                          ?.img || Privacy
                      }
                      alt="Start searching"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* FOOTER */}
        <Grid container justify="center" className="segment segment__title">
          <Grid container justify="center" className="footer-title">
            <Grid item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                align="center"
                className={
                  Object.keys(this.state.configurationTourtleSettings).length >
                  0
                    ? "title-footer skeleton skeleton-loaded"
                    : "title-footer skeleton"
                }
              >
                {this.state.configurationTourtleSettings["Closing Heading"] ??
                  "Keep Everything in One Place."}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item>
              <div
                onClick={this.props.userInfo ? this.goToHome : () => {}}
                className="footer-logo"
              >
                <Logo
                  landing={true}
                  userInfo={this.props.userInfo}
                  showSkeleton={
                    Object.keys(this.state.configurationTourtleSettings)
                      .length === 0
                  }
                  customLogo={
                    this.state.configurationTourtleSettings["Logo"]?.img
                  }
                />
              </div>
            </Grid>
          </Grid>
          {!this.props.userInfo && (
            <Grid container justify="center">
              <Grid item class="grid-item sign-up-container">
                <div className="sign-up-button-container-footer">
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="options"
                    className="sign-up-button"
                    onClick={this.goToSignUp}
                  >
                    Sign up for free
                  </Button>
                </div>
                <Typography
                  gutterBottom
                  variant="body1"
                  className="already-have-account"
                >
                  Already have an account?{" "}
                  <span onClick={this.goToSignIn} className="sign-in-button">
                    Sign in
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {this.props.userInfo && (
            <Grid container justify="center">
              <Grid item class="grid-item already-signed-in-container">
                <Typography
                  gutterBottom
                  variant="body1"
                  className="already-signed-in"
                >
                  (Click the logo above to toggle back to tourtle.)
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container className="footer-links mobile">
            <Grid item xs={1} />
            <Grid item xs={3} sm={3} md={3}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button left"
                align="center"
                onClick={this.gotoAbout}
              >
                About
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button"
                align="center"
                onClick={this.gotoHelp}
              >
                Help
              </Typography>
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              <Typography
                gutterBottom
                variant="body1"
                className="footer-button right"
                align="center"
                onClick={this.gotoPolicies}
              >
                Policies
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>

          <Grid container className="footer-links desktop">
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoAbout}
            >
              About
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoHelp}
            >
              Help
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              className="footer-button desktop"
              align="center"
              onClick={this.gotoPolicies}
            >
              Policies
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className="segment lesser-top-padding extra-bottom-padding"
        >
          <Grid item xs={5} sm={5} md={5} className="footer-spacer">
            <Typography gutterBottom variant="body2" align="left">
              <a className="footer-contact" href="mailto: contact@tourtle.com">
                contact@tourtle.com
              </a>
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography
              gutterBottom
              variant="body1"
              align="right"
              className="footer-copy"
            >
              © {moment().format("YYYY")} tourtle inc.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userInfo: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  cookieDialogOpening: actions.fireCookieDialogOpening,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewLandingPage)
);
