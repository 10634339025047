/* cSpell:ignore Ctoken */

import querystring from "querystring";
import isEmpty from "lodash/isEmpty";
import startsWith from "lodash/startsWith";

const parseQueryString = queryString => {
  if (isEmpty(queryString)) return {};
  const stringToParse = startsWith(queryString, "?")
    ? queryString.slice(1)
    : queryString;
  return querystring.parse(stringToParse);
};

// prettier-ignore
export default {
  getCurrentUserInfo                    : state => JSON.parse(JSON.stringify(state.root.user.info)),
  getIsLeftNavOpen                      : state => state.root.app.leftNav.open,
  getHomeListItems                      : state => state.root.app.homeList.items,
  getHomeListIsFetching                 : state => state.root.app.homeList.isFetching,
  getHomeListCToken                     : state => state.root.app.homeList.cToken,
  getHomeListHasNext                    : state => state.root.app.homeList.hasNext,
  getHomeListSectionCount               : state => state.root.app.homeList.sectionCount,
  getWelcomeTourtle                     : state => state.root.app.welcomeTourtle,
  getTrendingListItems                  : state => state.root.app.trendingList.items,
  getTrendingListIsFetching             : state => state.root.app.trendingList.isFetching,
  getTrendingListCToken                 : state => state.root.app.trendingList.cToken,
  getTrendingListHasNext                : state => state.root.app.trendingList.hasNext,
  getTrendingListSectionCount           : state => state.root.app.trendingList.sectionCount,
  getSubsTourtleListItems               : state => state.root.user.subsTourtleList.items,
  getSubsTourtleListCToken              : state => state.root.user.subsTourtleList.cToken,
  getSubsTourtleListHasNext             : state => state.root.user.subsTourtleList.hasNext,
  getSubsTourtleListIsFetching          : state => state.root.user.subsTourtleList.isFetching,
  getSearchTourtleListItems             : state => state.root.app.searchList.items,
  getSearchTourtleListCToken            : state => state.root.app.searchList.cToken,
  getSearchTourtleListHasNext           : state => state.root.app.searchList.hasNext,
  getSearchTourtleListIsFetching        : state => state.root.app.searchList.isFetching,
  getSubscriptions                      : state => state.root.user.info ? state.root.user.info.subscriptions : [],
  getAuthor                             : state => state.root.app.author.item,
  getAuthorTourtleListItems             : state => state.root.app.authorTourtleList.items,
  getAuthorTourtleListIsFetching        : state => state.root.app.authorTourtleList.isFetching,
  getAuthorTourtleListHasNext           : state => state.root.app.authorTourtleList.hasNext,
  getAuthorTourtleListCToken            : state => state.root.app.authorTourtleList.cToken,
  getCurrentUserTourtleListItems        : state => state.root.user.tourtleList.items,
  getCurrentUserTourtleListIsFetching   : state => state.root.user.tourtleList.isFetching,
  getCurrentUserTourtleListCToken       : state => state.root.user.tourtleList.cToken,
  getCurrentUserTourtleListHasNext      : state => state.root.user.tourtleList.hasNext,
  getVisitedTourtleListItems            : state => state.root.user.recentlyVisitedTourtleList.items,
  getVisitedTourtleListIsFetching       : state => state.root.user.recentlyVisitedTourtleList.isFetching,
  getCurrentUserCollectionItems         : state => state.root.user.collectionList.items,
  getCurrentUserCollectionIsFetching    : state => state.root.user.collectionList.isFetching,
  getCurrentUserCollectionCToken        : state => state.root.user.collectionList.cToken,
  getCurrentUserCollectionHasNext       : state => state.root.user.collectionList.hasNext,
  getCurrentUserFavoriteItems           : state => state.root.user.favoriteTourtleList.items,
  getCurrentUserFavoritesIsFetching     : state => state.root.user.favoriteTourtleList.isFetching,
  getCurrentUserFavoritesCToken         : state => state.root.user.favoriteTourtleList.cToken,
  getCurrentUserFavoritesHasNext        : state => state.root.user.favoriteTourtleList.hasNext,
  getDeleteTourtleDialog                : state => state.root.dialog.deleteTourtleDialog,
  getShareDialog                        : state => state.root.dialog.shareDialog,
  getIsShareDialogOpen                  : state => state.root.dialog.shareDialog.open,
  getReportDialog                       : state => state.root.dialog.reportDialog,
  getAddTourtleToGroupsConfirmationDialog            : state => state.root.dialog.addTourtleToGroupsConfirmationDialog,
  getAddTourtleToGroupDialog            : state => state.root.dialog.addTourtleToGroupDialog,
  getPrivateLinkInformationStep         : state => state.root.dialog.privateLinkInformationStep.step,
  getAddTourtleToCollectionDialog       : state => state.root.dialog.addTourtleToCollectionDialog,
  getLoginDialog                        : state => state.root.dialog.loginDialog,
  getSignInUpDialog                     : state => state.root.dialog.signInUpDialog,
  getSearchResultInMyGroups             : state => state.root.app.groupList.myGroups,
  getSearchResultInOtherGroups          : state => state.root.app.groupList.otherGroups,
  getSearchGroupsIsFetching             : state => state.root.app.groupList.isFetching,
  getSignOutDialog                      : state => state.root.dialog.signOutDialog,
  getSnackbar                           : state => state.root.app.snackbar,
  getIsForgetting                       : state => state.root.user.isForgetting,
  getCookieDialog                       : state => state.root.dialog.cookieDialog,
  getUserInterests                      : state => state.root.user.userInterest.interests,
  getWarningDialog                      : state => state.root.dialog.warningDialog,
  getUnsavedChangesDialog               : state => state.root.dialog.unsavedChangesDialog,
  getLocationDialog                     : state => state.root.dialog.locationServiceDialog,
  getInterestsTourtleListItems          : state => state.root.app.interestsTourtleList.items,
  getInterestsTourtleListCToken         : state => state.root.app.interestsTourtleList.cToken,
  getInterestsTourtleListHasNext        : state => state.root.app.interestsTourtleList.hasNext,
  getInterestsTourtleListIsFetching     : state => state.root.app.interestsTourtleList.isFetching,
  getInterestsAuthorListItems           : state => state.root.app.interestsAuthorList.items,
  getInterestsAuthorListCToken          : state => state.root.app.interestsAuthorList.cToken,
  getInterestsAuthorListHasNext         : state => state.root.app.interestsAuthorList.hasNext,
  getInterestsAuthorListIsFetching      : state => state.root.app.interestsAuthorList.isFetching,
  getLastCreatedTourtleData             : state => state.root.app.tourtleItem.lastCreatedTourtleData,
  getTourtleItem                        : state => state.root.app.tourtleItem.item,
  getTourtleSteps                       : state => state.root.app.tourtleItem.steps ? state.root.app.tourtleItem.steps : [],
  getJumpToStepDialog                   : state => state.root.dialog.jumpToStepDialog,
  getImageGallery                       : state => state.root.dialog.imageGallery,
  getLinkedTourtleIndexes               : state => state.root.user.linkedTourtleHistoryIndexes.indexes,
  getAddStepDialog                      : state => state.root.dialog.addStepDialog,
  getTourtleComments                    : state => state.root.app.tourtleItem.comments,
  getOwnRating                          : state => state.root.app.tourtleItem.ownRating,
  getSelectedStepId                     : state => state.root.app.selectedStepId,
  stepRefId                             : state => state.root.app.addRef.id,
  getCancelAddingStep                   : state => state.root.app.tourtleItem.cancelAddingStep,
  getIsUpdatingComment                  : state => state.root.app.tourtleItem.isUpdatingComment,
  getDeleteStepDialog                   : state => state.root.dialog.deleteStepDialog,
  getDuplicateStepDialog                : state => state.root.dialog.duplicateStepDialog,
  getIsDuplicatingStep                  : state => state.root.app.tourtleItem.isStepDuplicating,
  getMoveStepDialog                     : state => state.root.dialog.moveStepDialog,
  getUpdateTourtleItem                  : state => state.root.app.updateTourtleItem.tourtle,
  getIfImageIsUploading                 : state => state.root.app.tempImage.uploading,
  getIfAudioIsUploading                 : state => state.root.app.tempAudio.uploading,
  getIfVideoIsUploading                 : state => state.root.app.tempVideo.uploading,
  getContentModerationDialogState       : state => state.root.dialog.contentModerationState,
  getCurrentStepIndex                   : state => state.root.app.currentStepIndex,
  getTourtleCoverPageTopOffset          : state => state.root.app.tourtleCoverPageTopOffset,
  getTabbedListScrollPosition           : state => state.root.app.tabbedListScrollPosition,
  getSearchDialog                       : state => state.root.dialog.searchDialog,
  filterDialogOpen                      : state => state.root.dialog.filterDialog.open,
  filterDialogIsOpen                    : state => state.root.dialog.filterDialog.isOpen,
  getFilterSettings                     : state => state.root.dialog.filterDialog.settings,
  getVisibilityWasUpdated               : state => state.root.app.groupItem.visibilityWasUpdated,
  getGroupItem                          : state => state.root.app.groupItem.item,
  getSelectedGroupId                    : state => state.root.app.selectedGroupId,
  getIsAddMemberMenuOpen                : state => state.root.dialog.addMemberMenu.open,
  getAddedMembers                       : state => state.root.dialog.addMemberMenu.added,
  getIsAddViewersOpen                   : state => state.root.dialog.addViewers.open,
  getAddedViewers                       : state => state.root.dialog.addViewers.added,
  invitationDialog                      : state => state.root.dialog.isInvitationDialogOpen.open,
  getDeleteGroupDialog                  : state => state.root.dialog.deleteGroupDialog,
  getIsDeleteGroupDialogOpen            : state => state.root.dialog.deleteGroupDialog.open,
  isLeaveGroupDialog                    : state => state.root.dialog.leaveGroupDialog.open,
  getIsLastAdminDialogOpen              : state => state.root.dialog.isLastAdminDialogOpen.open,
  getIsFetchingGroupTourtleList         : state => state.root.app.groupItem.isFetchingList,
  getGroupTourtleListItems              : state => state.root.app.groupItem.tourtles,
  getGroupListQuery                     : state => state.root.app.groupItem.query,
  getGroupTourtleListCToken             : state => state.root.app.groupItem.cToken,
  getGroupTourtleListHasNext            : state => state.root.app.groupItem.hasNext,
  getSearchQuery                        : state => state.root.app.searchQuery,
  getSearchQueryGroups                  : state => state.root.app.searchQueryGroups,
  getRemoveTourtleFromGroupDialog       : state => state.root.dialog.removeTourtleFromGroupDialog,
  getIsRemoveTourtleFromGroupDialogOpen : state => state.root.dialog.removeTourtleFromGroupDialog.open,
  getIsRequestPending                   : state => state.root.app.isRequestPending.pending,
  getGroupMembers                       : state => state.root.app.groupItem.members,
  getRevokeInvitationDialogOpen         : state => state.root.dialog.revokeInvitationDialog.open,
  getGroupItemSettings                  : state => state.root.app.groupItem.item.settings,
  getMoveTourtleDialog                  : state => state.root.dialog.moveTourtleDialog,
  getDeleteCollectionDialog             : state => state.root.dialog.deleteCollectionDialog,
  getTourtlesForCollection              : state => state.root.app.collectionItem.tourtles,
  getTourtlesForCollectionCToken        : state => state.root.app.collectionItem.cToken,
  getTourtlesForCollectionHasNext       : state => state.root.app.collectionItem.hasNext,
  getCollection                         : state => state.root.app.collectionItem.item,
  getTourtlesForCollectionIsFetching    : state => state.root.app.collectionItem.isUpdatingCollection,
  clearCollectionStateInRedux           : state => state.root.app.clearCollectionStateInRedux.clear,
  searchResultValue                     : state => state.root.app.searchResultValue,
  getSelectedTourtleList                : state => state.root.app.selectedTourtleList.items,
  getSelectedTourtle                    : state => state.root.app.selectedTourtleList,
  getRemovedTourtleListItems            : state => state.root.app.selectedTourtleList.removedItems,
  getSelectedTourtles                   : state => state.root.app.selectedTourtleList.addedTourtles,
  getDiscardChangesDialog               : state => state.root.dialog.discardChangesDialog,
  getIsCreatingCollection               : state => state.root.app.collectionItem.isCreatingCollection,
  getIsAddStepModeOn                    : state => state.root.app.isAddStepModeOn.open,
  getIsEditStepModeOn                   : state => state.root.app.isEditStepModeOn.open,
  getSelectedTourtleId                  : state => state.root.app.selectedTourtleId,
  getStepCounter                        : state => state.root.app.tourtleItem.createdStepCounter,
  getAlreadyUsedStorageSpace            : state => state.root.user.info ? state.root.user.info.stats.totalStorageUsed : 0,
  getAllStorageSpace                    : state => state.root.user.info ? state.root.user.info.settings.storageSpace : 0,
  getRemoveLinkedTourtleDialog          : state => state.root.dialog.removeLinkedTourtleDialog,
  getAreStatisticsFresh                 : state => state.root.admin.areStatisticsFresh,

  shouldHighlightFilterButton           : state => {

    if(state.root.dialog.filterDialog.settings) {

      if (state.root.dialog.filterDialog.settings.interests.length > 0 || state.root.dialog.filterDialog.settings.publishDate !== "0" ) {
        return true;
      }

      if (state.root.dialog.filterDialog.settings.me === false &&
        state.root.dialog.filterDialog.settings.groups === false &&
        state.root.dialog.filterDialog.settings.group_members === false &&
        state.root.dialog.filterDialog.settings.subscriptions === false &&
        state.root.dialog.filterDialog.settings.everyone_else === false &&
        state.root.dialog.filterDialog.settings.collaborations === false) { 
          return false;
      } else if (
        !(
          state.root.dialog.filterDialog.settings.me === true &&
          state.root.dialog.filterDialog.settings.groups === true &&
          state.root.dialog.filterDialog.settings.group_members === true &&
          state.root.dialog.filterDialog.settings.subscriptions === true &&
          state.root.dialog.filterDialog.settings.everyone_else === true &&
          state.root.dialog.filterDialog.settings.collaborations === true
        )
      ) {
        return true;
      }
    }
    return false;
  },

  getIsImageDeleteDialogOpen            : state => state.root.dialog.imageDeleteDialog.open,
  getIsAudioDeleteDialogOpen            : state => state.root.dialog.audioDeleteDialog.open,
  getIsVideoDeleteDialogOpen            : state => state.root.dialog.videoDeleteDialog.open,
  getAddTourtlesToGroupDialogState       : state => state.root.dialog.addTourtlesToGroupDialog,
  getIsFetchingItem                     : state => state.root.app.tourtleItem.isFetchingItem,
  getIsFetchingAuthor                   : state => state.root.app.tourtleItem.isFetchingAuthor,
  getTourtleCommentsCToken              : state => state.root.app.tourtleItem.commentsCtoken,
  getIsCreatingStep                     : state => state.root.app.tourtleItem.isCreatingStep,
  getIsDeletingStep                     : state => state.root.app.tourtleItem.isDeletingStep,
  getIsRemovingLinkedTourtle            : state => state.root.app.tourtleItem.isRemovingLinkedTourtle,
  getIsMovingStep                       : state => state.root.app.tourtleItem.isMovingStep,
  getIsUpdatingStep                     : state => state.root.app.tourtleItem.isUpdatingStep,
  getUpdateStepSuccessful             : state => state.root.app.tourtleItem.updateStepSuccessful,
  getTourtleId                          : state => state.root.app.tourtleItem.item.id,
  getSuggestedTourtleList               : state => state.root.app.suggestedTourtleList.items,
  getBasicTourtle                       : state => state.root.app.tourtleList.basicTourtle,
  getCollaborators                      : state => state.root.app.tourtleItem.collaborators,
  getGroupInvitations                   : state => state.root.app.groupItem.invitations,
  showInitialGroups                     : state => state.root.app.groupItem.showInitialGroups,
  getAuthorListItems                    : state => state.root.app.authorList.items,
  getAuthorListFetch                    : state => state.root.app.authorList.isFetching,
  getPendingTourtles                    : state => state.root.app.groupItem.requests,
  getIsRequestingLoginLink              : state => state.root.login.isRequestingLoginLink,
  getIsUnrecognizedUsername             : state => state.root.login.isUnrecognizedUsername,
  getInactiveUser                       : state => state.root.login.inactiveUser,
  getUnverifiedUser                     : state => state.root.login.unverifiedUser,
  getLoginUsername                      : state => state.root.login.username,
  getLoginChannelAddress                : state => state.root.login.address,
  getChannelSelection                   : state => state.root.signUp.channel,
  getIsAvailable                        : state => state.root.signUp.isAvailable,
  getIsAvailabilityChecked              : state => state.root.signUp.isAvailabilityChecked,
  getIsChannelSelected                  : state => state.root.signUp.isChannelSelected,
  getIsCheckingAvailability             : state => state.root.signUp.isCheckingAvailability,
  getIsCheckedFirstNameBad              : state => state.root.signUp.isCheckedFirstNameBad,
  getIsCheckedLastNameBad               : state => state.root.signUp.isCheckedLastNameBad,
  getIsCheckedCompanyBad                : state => state.root.signUp.isCheckedCompanyBad,
  getIsCheckedUsernameBad               : state => state.root.signUp.isCheckedUsernameBad,
  getIsCheckInputIncomplete             : state => state.root.signUp.isCheckedInputIncomplete,
  getIsCheckedUsernameForbidden         : state => state.root.signUp.isCheckedUsernameForbidden,
  getIsSignUpFailed                     : state => state.root.signUp.isSignUpFailed,
  getIsSignUpInProgress                 : state => state.root.signUp.isSignUpInProgress,
  getSignUpEmail                        : state => state.root.signUp.email,
  getSignUpPhone                        : state => state.root.signUp.phone,
  getSignUpFirstName                    : state => state.root.signUp.firstName,
  getSignUpLastName                     : state => state.root.signUp.lastName,
  getSignUpCompany                      : state => state.root.signUp.company,
  getSignUpUsername                     : state => state.root.signUp.username,
  getAddressTakenDialog                 : state => state.root.signUp.addressTakenDialog,
  getAddressTakenDialogOpen             : state => state.root.signUp.addressTakenDialog.open,
  getBlackAndWhiteListFetching          : state => state.root.admin.blackWhiteList.isFetching,
  getAddWordsDialogOpen                 : state => state.root.admin.blackWhiteList.addWordsDialogOpen,
  getRemoveWordsDialogOpen              : state => state.root.admin.blackWhiteList.removeWordsDialogOpen,
  getAddWordsType                       : state => state.root.admin.blackWhiteList.addWordsType,
  getRemoveWordsType                    : state => state.root.admin.blackWhiteList.removeWordsType,
  getRemoveWords                        : state => state.root.admin.blackWhiteList.removeWords,
  getBlackList                          : state => state.root.admin.blackWhiteList.blackList,
  getWhiteList                          : state => state.root.admin.blackWhiteList.whiteList,
  getIsFetchingCurrentUser              : state => state.root.user.isFetching,
  getQueryString                        : state => parseQueryString(state.root.router.location.search),
  getIsDeletingUser                     : state => state.root.admin.isDeletingUser,
  getIsRefreshingStats                  : state => state.root.admin.isRefreshingStats,
  getIsUpdatingIsVerifiedAndIsActive    : state => state.root.admin.isUpdatingIsVerifiedAndIsActive,
  getIsUpdatingCategories               : state => state.root.admin.isUpdatingCategories,
  getIsFetchingReport                   : state => state.root.admin.isFetchingReport,
  getAdminTourtleReport                 : state => state.root.admin.report.tourtleStats,
  getAdminUserReport                    : state => state.root.admin.report.userStats,
  getAdminActivityReport                : state => state.root.admin.report.activityStats,
  getAdminCollectionReport              : state => state.root.admin.report.collectionStats,
  getAdminGroupReport                   : state => state.root.admin.report.groupStats,
  getTransferee                         : state => state.root.transfer.transferee,
  getTransferSearchIsFetching           : state => state.root.transfer.isFetching,
  getTransferSearchPattern              : state => state.root.transfer.searchPattern,
  getTransferSearchUsers                : state => state.root.transfer.users,
  getTransferSelectedUser               : state => state.root.transfer.selectedUser,
  getTransferSearchUsersNotFound        : state => state.root.transfer.usersNotFound,
  getAllCollections                     : state => state.root.app.collectionList.allItems,
  isAddTourtleToCollectionDialogOpen    : state => state.root.app.addTourtleToCollectionDialog.open,
  getActiveCollectionId                 : state => state.root.app.selectedCollectionId,
  searchWithEmptyQuery                  : state => state.root.app.fireSearchWithEmptyQuery.search,
  interestDialogOpen                    : state => state.root.user.userInterest.open,
  originOfRoute                         : state => state.root.app.originOfRoute.type,
  getFirstUse                           : state => state.root.user.firstUse,
  getCognitoUser                        : state => state.root.user.cognitoUser,
  getIsCommenting                       : state => state.root.app.commenting.value,

  getGroupDiscoveryListData             : state => state.root.app.groupList,
  getGroupDiscoveryListItems            : state => state.root.app.groupList.items,
  getGroupDiscoveryListHasNext          : state => state.root.app.groupList.hasNext,
  getGroupDiscoveryListCToken           : state => state.root.app.groupList.cToken,
  getGroupDiscoveryListSectionCount     : state => state.root.app.groupList.sectionCount,
  getGroupDiscoveryListIsFetching       : state => state.root.app.groupList.isFetching,
  getGroupTabValue                      : state => state.root.app.groupList.groupTabValue,

  selectedAttachment                    : state => state.root.app.selectedAttachment.attachment,
  attachmentPreview                     : state => state.root.app.attachmentPreview.open,
  isSendEmailToMemberDialogOpen         : state => state.root.app.sendEmailToMemberDialog.open,
  isEmailWasSent                        : state => state.root.app.sendEmailToMembers.showSnackbar,
  sendEmailToMembersRedux               : state => state.root.app.sendEmailToMembers,
  stepListCheck                         : state => state.root.app.stepListCheck,
};
