import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import api from "../../../api";

import actions from "../../../ducks/actions";
import selectors from "../../../ducks/selectors";

// Components
import MenuDrawer from "./MenuDrawer";
import SubscriptionThresholdDialog from "../SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../StorageLimit90PercentDialog";

// Icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/Share";
import ReportIcon from "@material-ui/icons/Report";
import TransferIcon from "@material-ui/icons/ExitToApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GroupIcon from "@material-ui/icons/Group";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import LibraryAddOutlinedIcon from "@material-ui/icons/LibraryAddOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import MoveIcon from "@material-ui/icons/SwapVert";

class TourtleMenuDrawer extends Component {
  state = {
    items: [],
    showLimitDialog: false,
    showAlmostLimitDialog: false,
    exceededLimits: {
      exceededStorage: false,
      exceededAudioLength: false,
      exceededVideoLength: false,
    },
    duplicateTourtle: {},
  };

  componentDidUpdate(prevProps) {
    const { tourtle, userInfo, listType } = this.props;
    if (prevProps.tourtle !== tourtle) {
      if (!_.isEmpty(tourtle)) {
        const isAdmin = userInfo.isAdmin;
        const isMine = tourtle.publisher.id === userInfo.id ? true : false;

        const isCollaborator =
          tourtle.collaborators &&
          tourtle.collaborators.filter(
            collaborator =>
              collaborator.id === userInfo.id &&
              (collaborator.type || "COLLABORATOR") === "COLLABORATOR"
          ).length > 0
            ? true
            : false;

        const isViewer =
          tourtle.collaborators &&
          tourtle.collaborators.filter(
            collaborator =>
              collaborator.id === userInfo.id &&
              (collaborator.type || "COLLABORATOR") === "VIEWER"
          ).length > 0
            ? true
            : false;

        const canAddToGroup =
          tourtle.settings &&
          tourtle.settings.allowOthersToAddToGroup &&
          (tourtle.visibility === "PUBLIC" ||
            (tourtle.visibility === "UNLISTED" &&
              tourtle.publisher.id === userInfo.id))
            ? true
            : false;

        const groupTourtle = listType === "groupTourtles" ? true : false;
        const collectionTourtle =
          listType === "collectionTourtles" ||
          listType === "favoriteCollectionTourtles"
            ? true
            : false;
        const favoriteCollection =
          listType === "favoriteCollectionTourtles" ? true : false;

        let canRemoveFromGroup = false;
        userInfo.groups.forEach((group, _) => {
          if (group.id === this.props.activeGroupId) {
            if (
              group.role === "ADMIN" ||
              (group.settings &&
                group.settings.removeByAdminOnly === false &&
                group.role === "MEMBER" &&
                tourtle &&
                tourtle.publisher &&
                tourtle.publisher.id === userInfo.id)
            ) {
              canRemoveFromGroup = true;
            }
          }
        });

        const groupListItemUnlisted =
          listType === "groupTourtles" && tourtle.visibility === "UNLISTED";
        let items = [];

        if ((isMine || isCollaborator) && !collectionTourtle) {
          items.push({
            key: "editTourtle",
            title: "Edit tourtle",
            icon: <EditIcon />,
          });
        }
        items.push({
          key: "manageCollaboration",
          title: "Manage collaborators/viewers",
          icon: <GroupIcon />,
        });
        if ((isMine || isCollaborator) && !groupTourtle && !collectionTourtle) {
          items.push({
            key: "deleteTourtle",
            title: "Delete tourtle",
            icon: <DeleteIcon />,
          });
        }
        if (
          isMine ||
          (tourtle.settings.allowOthersToDuplicate &&
            tourtle.visibility === "PUBLIC")
        ) {
          items.push({
            key: "duplicateTourtle",
            title: "Duplicate this tourtle",
            icon: <FileCopyIcon />,
          });
        }
        if (groupTourtle && (isMine || canRemoveFromGroup)) {
          items.push({
            key: "removeFromGroup",
            title: "Remove tourtle from group",
            icon: <DeleteOutlinedIcon />,
          });
        }
        if (!isMine && !collectionTourtle) {
          items.push({
            key: "reportTourtle",
            title: "Report tourtle",
            icon: <ReportIcon />,
          });
        }
        if (
          (isMine || canAddToGroup) &&
          !collectionTourtle &&
          !groupListItemUnlisted
        ) {
          items.push({
            key: "addToGroup",
            title: "Add to group(s)",
            icon: <PlaylistAddIcon />,
          });
        }
        if (
          (isMine ||
            isCollaborator ||
            isViewer ||
            tourtle.visibility === "PUBLIC" ||
            (tourtle.groups &&
              tourtle.groups.length > 0 &&
              (tourtle.visibility === "PRIVATE" ||
                tourtle.visibility === "UNLISTED"))) &&
          !collectionTourtle
        ) {
          items.push({
            key: "addToCollection",
            title: "Add to collection(s)",
            icon: <LibraryAddOutlinedIcon />,
          });
        }
        if (isAdmin) {
          items.push({
            key: "transferTourtle",
            title: "Transfer tourtle",
            icon: <TransferIcon />,
          });
        }
        if (collectionTourtle && !favoriteCollection) {
          items.push({
            key: "removeFromCollection",
            title: "Remove tourtle from collection",
            icon: <DeleteOutlinedIcon />,
          });
        }
        if (collectionTourtle && !favoriteCollection) {
          items.push({
            key: "moveTourtle",
            title: "Move tourtle",
            icon: <MoveIcon />,
          });
        }
        items.push({
          key: "shareTourtle",
          title: "Share tourtle",
          icon: <ShareIcon />,
        });

        this.setState({ items: items });
      }
    }
  }

  menuDrawerItemClick = key => {
    switch (key) {
      case "editTourtle":
        this.editTourtle();
        break;
      case "duplicateTourtle":
        this.duplicateTourtle();
        break;
      case "manageCollaboration":
        this.manageCollaboration();
        break;
      case "deleteTourtle":
        this.deleteTourtle();
        break;
      case "removeFromGroup":
        this.removeFromGroup();
        break;
      case "reportTourtle":
        this.reportTourtle();
        break;
      case "addToGroup":
        this.addToGroup();
        break;
      case "addToCollection":
        this.addToCollection();
        break;
      case "transferTourtle":
        this.transferTourtle();
        break;
      case "removeFromCollection":
        this.removeFromCollection();
        break;
      case "moveTourtle":
        this.moveTourtle();
        break;
      case "shareTourtle":
        this.shareTourtle();
        break;
      default:
        return;
    }
  };

  editTourtle = async () => {
    const { handleCloseMenuDrawer, history, tourtle } = this.props;

    handleCloseMenuDrawer();
    history.push({
      pathname: `/update-tourtle/${tourtle.id}`,
      publisherId: tourtle.publisher.id,
    });
  };

  duplicateTourtle = async () => {
    const {
      handleCloseMenuDrawer,
      history,
      tourtle,
      openSnackbar,
      userInfo,
    } = this.props;

    handleCloseMenuDrawer();
    const tourtleSize = tourtle.stats.totalStorageUsed;
    const storageLimit = userInfo.subscription.values.storageLimit;
    const storageUsed = userInfo.stats.totalStorageUsed;
    const isAdmin = userInfo.isAdmin;

    const newStorageUsed = tourtleSize + storageUsed;

    if (newStorageUsed > storageLimit && !isAdmin) {
      this.handleShowLimitDialog({
        exceededStorage: true,
        exceededAudioLength: false,
        exceededVideoLength: false,
      });
    } else {
      try {
        const result = await api.duplicateTourtle(tourtle.id);
        if (result.error) throw result;

        const duplicateId = result.id;
        if (duplicateId) {
          if (tourtle.title.length + 7 >= 70) {
            openSnackbar(
              "generic_success",
              "The title was truncated to the maximum of 70 characters"
            );
          }

          this.setState({
            duplicateTourtle: {
              pathname: `/tourtle/${duplicateId}`,
              publisherId: tourtle.publisher.id,
            },
          });

          if (newStorageUsed > storageLimit * 0.9 && !isAdmin) {
            this.handleShowAlmostLimitDialog();
          } else {
            history.push(this.state.duplicateTourtle);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  manageCollaboration = async () => {
    const { handleCloseMenuDrawer, history, tourtle } = this.props;

    handleCloseMenuDrawer();
    history.push({
      pathname: "/manage-collaboration/" + tourtle.id,
      state: {
        tourtleTitle: tourtle.title,
        publisher: tourtle.publisher.id,
        from: history.location.pathname,
      },
    });
  };

  deleteTourtle = () => {
    const {
      handleCloseMenuDrawer,
      onDeleteTourtleDialogOpening,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onDeleteTourtleDialogOpening(tourtle);
  };

  removeFromGroup = () => {
    const {
      handleCloseMenuDrawer,
      onRemoveTourtleFromGroupDialogOpening,
      onFireWarningDialogOpening,
      tourtle,
      group,
      userInfo,
    } = this.props;
    handleCloseMenuDrawer();
    if (group.settings && group.settings.removeByAdminOnly === true) {
      var isAdmin = false;
      for (var i = 0; i < userInfo.groups.length; i++) {
        if (
          userInfo.groups[i].id === group.id &&
          userInfo.groups[i].role === "ADMIN"
        ) {
          isAdmin = true;
          break;
        }
      }
      if (isAdmin) {
        onRemoveTourtleFromGroupDialogOpening(tourtle, group);
      } else {
        onFireWarningDialogOpening();
      }
    } else {
      onRemoveTourtleFromGroupDialogOpening(tourtle, group);
    }
  };

  reportTourtle = () => {
    const {
      handleCloseMenuDrawer,
      onReportDialogOpening,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onReportDialogOpening("tourtle", tourtle, {});
  };

  addToGroup = () => {
    const {
      handleCloseMenuDrawer,
      onAddTourtleToGroupDialogOpening,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onAddTourtleToGroupDialogOpening(tourtle);
  };

  addToCollection = () => {
    const {
      handleCloseMenuDrawer,
      onManageCollectionsDialogOpening,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onManageCollectionsDialogOpening(tourtle);
  };

  transferTourtle = () => {
    const { history, tourtle } = this.props;
    history.push(`/transfer/${tourtle.id}`);
  };

  removeFromCollection = async () => {
    const { handleCloseMenuDrawer, tourtle, removeFromCollection } = this.props;
    handleCloseMenuDrawer();
    removeFromCollection(tourtle);
  };

  moveTourtle = () => {
    const {
      handleCloseMenuDrawer,
      onMoveTourtleDialogOpening,
      tourtle,
    } = this.props;
    handleCloseMenuDrawer();
    onMoveTourtleDialogOpening(tourtle);
  };

  shareTourtle = () => {
    const {
      tourtle,
      onShareDialogOpen,
      onFireWarningDialogOpening,
      handleCloseMenuDrawer,
    } = this.props;
    if (tourtle.visibility !== "PRIVATE") {
      onShareDialogOpen(tourtle, "tourtle");
      handleCloseMenuDrawer();
    } else {
      onFireWarningDialogOpening("share-tourtle");
      handleCloseMenuDrawer();
    }
  };

  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
    this.props.history.push(this.state.duplicateTourtle);
  };

  render() {
    const { ...props } = this.props;
    return (
      <div>
        <MenuDrawer
          open={props.open}
          items={this.state.items}
          tourtle={props.tourtle}
          handleItemClick={this.menuDrawerItemClick}
          handleCloseMenuDrawer={props.handleCloseMenuDrawer}
        />
        {this.props.userInfo && (
          <SubscriptionThresholdDialog
            dialogOpen={this.state.showLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={this.props.userInfo.stats.totalStorageUsed}
            handleDialogClose={this.handleHideLimitDialog}
            exceededLimits={this.state.exceededLimits}
            action={"duplicateTourtle"}
          />
        )}
        {this.props.userInfo && (
          <StorageLimit90PercentDialog
            dialogOpen={this.state.showAlmostLimitDialog}
            subscriptionValues={this.props.userInfo.subscription.values}
            totalStorageUsed={this.props.userInfo.stats.totalStorageUsed}
            handleDialogClose={this.handleHideAlmostLimitDialog}
            exceededLimits={this.state.exceededLimits}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  activeGroupId: selectors.getSelectedGroupId(state),
  group: selectors.getGroupItem(state),
});

const mapDispatchToProps = {
  onDeleteTourtleDialogOpening: actions.fireDeleteTourtleDialogOpening,
  onRemoveTourtleFromGroupDialogOpening:
    actions.fireRemoveTourtleFromGroupDialogOpen,
  onReportDialogOpening: actions.fireReportDialogOpening,
  onAddTourtleToGroupDialogOpening: actions.fireAddToGroupDialogOpening,
  onManageCollectionsDialogOpening: actions.fireAddToCollectionDialogOpening,
  onShareDialogOpen: actions.fireShareDialogOpening,
  onMoveTourtleDialogOpening: actions.fireMoveTourtleDialogOpening,
  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  openSnackbar: actions.fireSnackbarOpening,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TourtleMenuDrawer)
);
