import React, { Component } from "react";

// CSS
import "./Logo.css";

class Logo extends Component {
  render() {
    return (
      <div
        className={
          this.props.landing
            ? this.props.showSkeleton
              ? this.props.customLogo
                ? "logo-container skeleton skeleton-loaded"
                : "logo-container skeleton"
              : ""
            : "logo-container"
        }
      >
        <img
          className={`
            ${
              this.props.login
                ? ""
                : this.props.landing
                ? "landing-logo"
                : "logo"
            }
            ${this.props.userInfo ? "pointer" : ""}
            `}
          alt="tourtle"
          src={
            this.props.landing
              ? this.props.customLogo
                ? this.props.customLogo
                : "/image/logo_new.png"
              : "/image/logo_new.png"
          }
        />
      </div>
    );
  }
}

export default Logo;
