import React, { Component } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import TextButton from "../common/TextButton/TextButton";
import AudioUpload from "../common/AudioUploadDense";
import VideoUpload from "../common/VideoUploadDense";
import AttachmentInput from "../common/FileUpload/AttachmentInput";
import SubscriptionThresholdDialog from "../common/SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";
import { isMobile } from "../Utils/detectDevice";
import { validateDescription } from "../Utils/helper";

// Material UI
import {
  FormControl,
  InputLabel,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  InputBase,
} from "@material-ui/core";

// Icons
import CloseIcon from "@material-ui/icons/Close";

import "./textEditor.css";
// import ImageUpload from "../common/ImageUploadDense";
import WarningDialog from "../common/WarningDialog";

// Other imports
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import PdfUpload from "../common/FileUpload/PdfDisplay";

import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUploadDense"));

const CustomInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: "22px",
    },
  },
  input: {
    fontSize: 16,
    fontFamily: ["Roboto", '"Helvetica"', "Arial", "sans-serif"].join(","),
  },
}))(InputBase);

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    paddingBottom: "80px",
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  titleInput: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    padding: ".625rem 1rem",
    color: "black",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  textControl: {},
  textLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    color: "black !important",
    cursor: "pointer",
  },
  marginControl: {
    width: "100%",
    marginTop: 20,
  },
  uploadContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  visibilityItem: {
    width: 75,
  },
  visibilityItemFullwidth: {
    width: "100%",
  },
  padTop: {
    paddingTop: "15px",
  },
  inlineImage: {
    verticalAlign: "bottom",
    marginRight: "8px",
    color: "#6d6d6d",
  },
  inlineImageAlert: {
    verticalAlign: "bottom",
    marginRight: "8px",
    color: "#fdce21",
  },
  button: {
    marginTop: 15,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    textTransform: "none",
  },
  tourtleTitle: {
    marginTop: 10,
    width: "100%",
    fontWeight: "",
    display: "-webkit-box",
    overflow: "hidden",
    whiteSpace: "normal",
    "-webkit-box-orient": "vertical",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 2,
    height: "2.8",
    lineHeight: "1.4rem",
  },
  overlay: {
    opacity: "0.5",
    background: "#000",
    width: "100%",
    height: "100%",
    zIndex: 1000,
    top: 0,
    left: 0,
    position: "fixed",
  },
  editorClass: {
    height: "100%",
    padding: "7px 12px",
    overflow: "auto",
    borderRadius: "10px",
    border: "1px solid #cdcdcd",
    boxSizing: "border-box",
    position: "relative",
    paddingBottom: 80,
    fontSize: 16,
  },
  toolbarClass: {
    zIndex: 200,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "whitesmoke",
    marginBottom: 0,
    position: "absolute !important",
    bottom: "0px !important",
    width: "100%",
    padding: "6px 0px 6px !important",
    border: "0px solid #fff !important",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  editorWrapper: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial','sans-serif'",
    "rdw-link-modal-btn": {
      fontWeight: 400,
    },
    position: "relative",
    marginTop: 25,
  },
  divider: {
    marginTop: 5,
    marginBottom: 10,
    backgroundColor: "#F47621",
  },
  removeAttachmentButton: {
    position: "absolute !important",
    right: "5% !important",
    top: "35% !important",
  },
  attachmentsTitle: {
    fontSize: "1.0rem",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: 20,
  },
});

class UpdateStep extends Component {
  constructor(props) {
    super(props);
    const newEditorState = EditorState.createEmpty();
    this.state = {
      editorState: newEditorState,
      image: null,
      audio: null,
      video: null,
      title: "",
      attachments: [],
      description: "",
      useAsCoverImage: false,
      showSaveDialog: false,
      newStepId: null,
      deviceIsIOS: false,
      stepCreatedOrUpdated: false,
      initialEditorState: newEditorState,
      initialImage: null,
      initialAudio: null,
      initialVideo: null,
      initialTitle: "",
      initialAttachments: [],
      initialDescription: "",
      initialUseAsCoverImage: false,
      loading: false,
      tourtleCreatedOrUpdated: false,
      mediaArray: ["image", "video", "audio"],
      cleanup: false,
      storageDelta: 0,
      tempStorage: 0,
      showLimitDialog: false,
      showAlmostLimitDialog: false,
      exceededLimits: {
        exceededStorage: false,
        exceededAudioLength: false,
        exceededVideoLength: false,
      },
    };

    this.myRef = React.createRef();
    this.renameAttachment = this.renameAttachment.bind(this);
    this.removeAttachment = this.removeAttachment.bind(this);
    this.imageUploadRef = React.createRef();
    this.videoUploadRef = React.createRef();
    this.audioUploadRef = React.createRef();
  }

  async componentDidMount() {
    api.getStorageUsed(this.props.self.id).then(value => {
      this.props.updateStorageUsed(value);
    });

    if (isMobile.iOS()) this.setState({ deviceIsIOS: true });
    window.scrollTo(0, 0);
    if (!this.props.tourtleItem.id) {
      this.getTourtleItem();
    }

    if (this.isEditing) {
      this.setStepState();
    } else {
      this.focusTitle();
    }

    if (this.props.location.state && !this.isEditing) {
      if (
        this.props.location.state.stepIndex === undefined ||
        this.props.location.state.stepIndex === null
      ) {
        this.props.onAddingStepFromCoverPageOn();
      }

      if (this.props.location.state.fromCoverPage) {
        this.props.onAddingStepFromCoverPageOn();
      }
    } else if (this.props.location.state && this.isEditing) {
      if (
        this.props.location.state.stepIndex === undefined ||
        this.props.location.state.stepIndex === null
      ) {
        this.props.onEditingStepFromCoverPageOn();
      }

      if (this.props.location.state.fromCoverPage) {
        this.props.onEditingStepFromCoverPageOn();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.image !== prevState.image ||
      this.state.video !== prevState.video ||
      this.state.audio !== prevState.audio
    ) {
      this.changeOrderMedia();
    }
  }

  changeOrderMedia = () => {
    if (
      (this.state.image === null &&
        this.state.video === null &&
        this.state.audio === null) ||
      (this.state.image !== null &&
        this.state.video === null &&
        this.state.audio === null) ||
      (this.state.image !== null && this.state.video !== null)
    ) {
      this.setState({ mediaArray: ["image", "video", "audio"] });
    } else if (
      this.state.image === null &&
      this.state.video !== null &&
      this.state.audio === null
    ) {
      this.setState({ mediaArray: ["video", "image", "audio"] });
    } else if (
      this.state.image === null &&
      this.state.video !== null &&
      this.state.audio !== null
    ) {
      this.setState({ mediaArray: ["video", "audio", "image"] });
    } else if (
      this.state.image === null &&
      this.state.video === null &&
      this.state.audio !== null
    ) {
      this.setState({ mediaArray: ["audio", "image", "video"] });
    } else if (
      this.state.image !== null &&
      this.state.video === null &&
      this.state.audio !== null
    ) {
      this.setState({ mediaArray: ["image", "audio", "video"] });
    }
  };

  getTourtleItem = async () => {
    const {
      onFetchTourtleItem,
      onFetchTourtleItemSuccess,
      onFetchTourtleItemFailure,
    } = this.props;

    try {
      onFetchTourtleItem(this.currentTourtleId);
      const response = await api.fetchTourtleItem(this.currentTourtleId);
      await onFetchTourtleItemSuccess(response);

      if (this.isEditing) {
        this.setStepState();
      }
    } catch (error) {
      onFetchTourtleItemFailure(error);
    }
  };

  get headerTitle() {
    return this.isEditing ? "Update item/step" : "Add new item/step";
  }

  get hasUnsavedChanges() {
    if (!this.isEditing) {
      if (
        this.state.image !== null ||
        this.state.audio !== null ||
        this.state.video !== null ||
        validateDescription(this.state.editorState) ||
        this.state.title !== "" ||
        this.state.attachments.length > 0
      ) {
        return true;
      }

      return false;
    } else {
      const editorState = JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      );

      const initialEditorState = JSON.stringify(
        convertToRaw(this.state.initialEditorState.getCurrentContent())
      );

      if (
        (this.state.image !== null ||
          this.state.audio !== null ||
          this.state.video !== null ||
          validateDescription(this.state.editorState) ||
          this.state.title !== "" ||
          this.state.attachments.length > 0) &&
        (this.state.image !== this.state.initialImage ||
          this.state.audio !== this.state.initialAudio ||
          this.state.video !== this.state.initialVideo ||
          this.state.title !== this.state.initialTitle ||
          editorState !== initialEditorState ||
          this.validateAttachments(
            this.state.attachments,
            this.state.initialAttachments
          ))
      ) {
        return true;
      } else if (this.state.image !== "" && this.state.useAsCoverImage) {
        return true;
      } else {
        return false;
      }
    }
  }

  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  validateAttachments(attachments, initialAttachments) {
    if (attachments.length !== initialAttachments.length) {
      return true;
    }

    return !initialAttachments.every(initialAttachment => {
      return attachments.find(
        attachment =>
          attachment.name === initialAttachment.name &&
          attachment.url === initialAttachment.url
      );
    });
  }

  setStepState() {
    const { tourtleSteps, match } = this.props;
    for (var i = 0; i < tourtleSteps.length; i++) {
      if (tourtleSteps[i].id === match.params.id) {
        if (
          tourtleSteps[i].description !== "" &&
          tourtleSteps[i].description !== undefined
        ) {
          try {
            const newDescription = convertFromRaw(
              JSON.parse(tourtleSteps[i].description)
            );
            const newContentState = EditorState.createWithContent(
              newDescription
            );
            this.setState({ editorState: newContentState });
            this.setState({ initialEditorState: newContentState });
          } catch (e) {
            const newContentState = EditorState.createWithContent(
              ContentState.createFromText(tourtleSteps[i].description)
            );
            this.setState({ editorState: newContentState });
            this.setState({ initialEditorState: newContentState });
          }
        }
        this.setState({
          image: tourtleSteps[i].image ? tourtleSteps[i].image : null,
          initialImage: tourtleSteps[i].image ? tourtleSteps[i].image : null,
          audio: tourtleSteps[i].audio ? tourtleSteps[i].audio : null,
          initialAudio: tourtleSteps[i].audio ? tourtleSteps[i].audio : null,
          video: tourtleSteps[i].video ? tourtleSteps[i].video : null,
          initialVideo: tourtleSteps[i].video ? tourtleSteps[i].video : null,
          title: tourtleSteps[i].title ? tourtleSteps[i].title : "",
          initialTitle: tourtleSteps[i].title ? tourtleSteps[i].title : "",
          attachments: tourtleSteps[i].attachments
            ? tourtleSteps[i].attachments
            : [],
          initialAttachments: (tourtleSteps[i].attachments
            ? tourtleSteps[i].attachments
            : []
          ).map(attachment => {
            return { ...attachment };
          }),
        });
      }
    }
  }

  handleUpdateStep = async () => {
    const type = "step";
    const {
      openContentModerationDialog,
      onFireWarningDialogOpening,
      onUpdateStep,
      setUpdateStepToFalse,
      tourtleItem,
      onUpdateStepSuccess,
      history,
      onUpdateStepFailure,
      match,
    } = this.props;
    if (
      (this.state.image === "" ||
        this.state.image === null ||
        this.state.image === undefined) &&
      (this.state.video === "" ||
        this.state.video === null ||
        this.state.video === undefined) &&
      (this.state.audio === "" ||
        this.state.audio === null ||
        this.state.audio === undefined) &&
      this.state.title === "" &&
      this.state.attachments.length === 0 &&
      !validateDescription(this.state.editorState)
    ) {
      onFireWarningDialogOpening();
    } else {
      try {
        this.setLoading();
        onUpdateStep();
        var object = {};
        object.title = this.state.title;
        object.description = JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        ).replace("?referer=share", "");
        object.image = this.state.image;
        object.audio = this.state.audio;
        object.video = this.state.video;
        object.attachments = this.state.attachments;
        object.useAsCoverImage = this.state.useAsCoverImage;
        object.cleanup = this.state.cleanup;
        const response = await api.updateStep(
          match.params.tourtleId,
          match.params.id,
          object
        );
        if (response.statusCode === 418) {
          this.setState({
            showSaveDialog: false,
          });
          setUpdateStepToFalse();
          openContentModerationDialog(
            response,
            type,
            tourtleItem.title,
            this.getIndexOfStep()
          );
        } else {
          let position = this.props.tourtleSteps
            .map(x => {
              return x.id;
            })
            .indexOf(response.id);
          this.props.finishStepAdd(position + 1);
          await onUpdateStepSuccess(
            response,
            this.state.image ? this.state.useAsCoverImage : false
          );
          this.setState({ stepCreatedOrUpdated: true });
          this.finishLoading();
          history.goBack();
        }
      } catch (error) {
        onUpdateStepFailure(error);
      }
    }
  };

  getIndexOfStep() {
    const { tourtleSteps, match } = this.props;
    for (var i = 0; i < tourtleSteps.length; i++) {
      if (tourtleSteps[i].id === match.params.id) {
        return tourtleSteps.indexOf(tourtleSteps[i]) + 1;
      }
    }
  }

  get currentStepId() {
    return this.props.match.params.id
      ? this.props.match.params.id
      : this.props.location.state.newStepId;
  }

  get currentTourtleId() {
    return this.props.match.params.tourtleId
      ? this.props.match.params.tourtleId
      : this.props.location.state.id;
  }

  get isEditing() {
    return !!this.props.match.params.id;
  }

  setLoading = () => {
    setTimeout(function() {
      if (this._isMounted) {
        this.setState({ loading: true });
      }
    }, 250);
  };

  finishLoading = () => this.setState({ loading: false });

  onTitleChange(value) {
    if (value.length <= 80) {
      this.setState({ title: value });
    }
  }

  onDescriptionChange = value => this.setState({ description: value });

  getSizeForUrl = async (url, removed = false) => {
    fetch(url, {
      headers: {
        Range: "bytes 0-0",
      },
    }).then(response => {
      let contentLength = parseInt(
        response.headers.get("Content-Length") || "0"
      );

      if (removed) {
        contentLength = contentLength * -1;
      }

      this.setState({
        storageDelta: this.state.storageDelta + contentLength,
        tempStorage: 0,
      });
    });
  };

  setStepImage = e => {
    this.setState({ image: e.url });
    this.getSizeForUrl(e.url);
  };
  removeStepImage = async () => {
    await this.getSizeForUrl(this.state.image, true);
    this.setState({ image: null, cleanup: true });
  };

  setStepAudio = e => {
    this.setState({ audio: e.url });
    this.getSizeForUrl(e.url);
  };
  removeStepAudio = async () => {
    await this.getSizeForUrl(this.state.audio, true);
    this.setState({ audio: null, cleanup: true });
  };

  setStepVideo = e => {
    this.setState({ video: e.url });
    this.getSizeForUrl(e.url);
  };
  removeStepVideo = async () => {
    await this.getSizeForUrl(this.state.video, true);
    this.setState({ video: null, cleanup: true });
  };

  setStepFile = e => {
    const attachment = {};
    attachment.name = e.file.name.replace(/\.[^.]*$/, "");
    attachment.url = e.awsUrl;

    const joined = this.state.attachments.concat(attachment);
    this.setState({ attachments: joined });
    this.getSizeForUrl(e.awsUrl);
  };

  handleUseAsCoverImage = async use =>
    this.setState({ useAsCoverImage: use.target.checked });

  updateOrCreateNew = async () => {
    if (this.isEditing) {
      this.handleUpdateStep();
    } else {
      this.handleCreateStep();
    }
  };

  handleCreateStep = async () => {
    const {
      openContentModerationDialog,
      onFireWarningDialogOpening,
      onCreateStep,
      onCreateStepSuccess,
      onCreateStepFailure,
      finishStepAdd,
      location,
      tourtleItem,
      tourtleSteps,
      history,
    } = this.props;
    if (!this.hasUnsavedChanges) return;

    const type = "step";
    if (
      this.state.title === "" &&
      !validateDescription(this.state.editorState) &&
      this.state.image === "" &&
      this.state.audio === "" &&
      this.state.video === "" &&
      this.state.attachments.length > 0
    ) {
      onFireWarningDialogOpening();
    } else {
      try {
        onCreateStep();
        var object = {};
        object.id = location.state.newStepId;
        object.useAsCoverImage = this.state.useAsCoverImage;
        object.cleanup = this.state.cleanup;
        if (this.state.title !== "") {
          object.title = this.state.title;
        }
        if (this.state.image !== "") {
          object.image = this.state.image;
        }
        if (this.state.audio !== "") {
          object.audio = this.state.audio;
        }
        if (this.state.video !== "") {
          object.video = this.state.video;
        }
        if (this.state.attachments.length > 0) {
          object.attachments = this.state.attachments;
        }
        if (this.state.editorState.getCurrentContent() !== "") {
          object.description = JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          );
        }
        if (location.state.stepIndex !== undefined) {
          object.position = location.state.stepIndex;
        }
        const response = await api.createStep(location.state.id, object);
        if (response.statusCode === 418) {
          this.setState({
            showSaveDialog: false,
          });
          openContentModerationDialog(
            response,
            type,
            tourtleItem.title,
            (location.state.stepIndex !== null) &
              (location.state.stepIndex !== undefined)
              ? location.state.stepIndex + 1
              : tourtleSteps.length + 1
          );
          onCreateStepFailure();
        } else {
          let newIndex = tourtleSteps.length;
          if (location.state.before === true) {
            newIndex = 0;
          } else if (location.state.stepIndex !== undefined) {
            newIndex = location.state.stepIndex;
          }
          await onCreateStepSuccess(
            response,
            newIndex,
            this.state.image ? this.state.useAsCoverImage : false
          );
          this.setState({ stepCreatedOrUpdated: true });
          location.state && location.state.stepIndex
            ? finishStepAdd(location.state.stepIndex + 1)
            : finishStepAdd(0);

          if (
            (location.state.stepIndex === null) |
            (location.state.stepIndex === undefined)
          ) {
            history.replace(`/tourtle/${this.props.location.state.id}`);
          } else {
            if (this.props.location.state.justCreated) {
              history.replace(`/tourtle/${this.props.location.state.id}`);
            } else {
              history.goBack();
            }
          }
        }
      } catch (error) {
        onCreateStepFailure(error);
      }
    }
  };

  handleBrowserBack = () => {
    if (!this.state.confirmedDiscard && !this.state.stepCreatedOrUpdated) {
      this.handleSaveDialogOpen();
      return false;
    }

    return true;
  };

  onHandleGoBack = () => {
    if (this.hasUnsavedChanges) {
      this.handleSaveDialogOpen();
    } else {
      this.definitiveGoBack();
    }
  };

  handleSaveDialogOpen = () => {
    if (this.hasUnsavedChanges) {
      this.setState({
        showSaveDialog: true,
      });
    } else {
      this.handleSaveDialogClose();
    }
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
      confirmedDiscard: true,
    });
    this.definitiveGoBack();
  };

  definitiveGoBack() {
    const { finishStepAdd, cancelAddingStep, history } = this.props;
    if (!this.isEditing) {
      cancelAddingStep(true);
    }

    if (this.props.location.state && this.props.location.state.justCreated) {
      this.handleDeleteJustCreatedTourtle();
    } else {
      this.props.location.state && this.props.location.state.stepIndex
        ? finishStepAdd(this.props.location.state.stepIndex + 1)
        : finishStepAdd(0);
      history.goBack();
    }
  }

  async handleDeleteJustCreatedTourtle() {
    const {
      onDeleteTourtleItem,
      onDeleteTourtleItemSuccess,
      onDeleteTourtleItemFailure,
      history,
    } = this.props;

    try {
      onDeleteTourtleItem();
      const res = await api.deleteTourtle(this.currentTourtleId);
      onDeleteTourtleItemSuccess(res);
      history.replace({
        pathname: `/addtourtle/`,
        state: {
          justCreated: true,
        },
      });
    } catch (error) {
      onDeleteTourtleItemFailure(error);
    }
  }

  onChange = editorState => {
    this.setState({ editorState: editorState });
  };

  focusTitle() {
    setTimeout(
      function() {
        this.myRef.current.focus();
      }.bind(this),
      250
    );
  }

  renameAttachment(e) {
    e.preventDefault();
    const { attachments } = this.state;
    let copyAttachments = this.state.attachments;
    copyAttachments[e.target.dataset.id][e.target.className] = e.target.value;
    this.setState(attachments, () => console.log(copyAttachments));
  }

  async removeAttachment(e, item) {
    e.preventDefault();
    const { attachments } = this.state;
    const newArray = attachments.filter(x => x.url !== item.url);
    await this.getSizeForUrl(item.url, true);
    this.setState({ attachments: newArray, cleanup: true });
  }

  render() {
    const { classes } = this.props;
    const { attachments } = this.state;

    return (
      <div className={classes.root}>
        <Prompt
          when={this.hasUnsavedChanges}
          message={this.handleBrowserBack}
        />
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title={this.headerTitle} />}
          right={
            <TextButton
              text="Save"
              outlineButton={true}
              disabled={!this.hasUnsavedChanges}
            />
          }
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={
            this.hasUnsavedChanges ? this.updateOrCreateNew : () => {}
          }
        />
        <div className={classes.content}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Typography component="h6" className={classes.tourtleTitle}>
                <b>{this.props.tourtleItem.title}</b>
              </Typography>
              <Divider className={classes.divider} />

              <div className={`${classes.uploadContainer}`}>
                <Grid
                  container
                  justifyContent="center"
                  justify="center"
                  className={classes.mediaContainer}
                >
                  {(this.state.mediaArray || []).map(item => (
                    <Grid
                      justifyContent="center"
                      justify="center"
                      className={
                        (item === "image" &&
                          this.state.image === null &&
                          this.props.isImageLoading === false) ||
                        (item === "video" && this.state.video === null) ||
                        (item === "audio" && this.state.audio === null)
                          ? classes.visibilityItem
                          : classes.visibilityItemFullwidth
                      }
                      item
                    >
                      {item === "image" && (
                        <div>
                          <ImageUpload
                            onRef={instance => {
                              this.imageUploadRef = instance;
                            }}
                            tourtleId={this.currentTourtleId}
                            stepId={this.currentStepId}
                            sendUrlBack={this.setStepImage}
                            removeImage={this.removeStepImage}
                            currentUrl={this.state.image}
                            isImageLoading={this.props.isImageLoading}
                            square={false}
                            type="step"
                            totalStorageUsed={
                              this.props.self.stats.totalStorageUsed +
                              this.state.storageDelta +
                              this.state.tempStorage
                            }
                            subscriptionValues={
                              this.props.self.subscription.values
                            }
                            handleShowLimitDialog={this.handleShowLimitDialog}
                            handleShowAlmostLimitDialog={
                              this.handleShowAlmostLimitDialog
                            }
                          />
                          {this.state.image !== undefined &&
                            this.state.image !== null &&
                            this.state.image !== "" && (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.useAsCoverImage}
                                    onChange={this.handleUseAsCoverImage}
                                    value="useAsCoverImage"
                                    color="primary"
                                  />
                                }
                                label="Use this item image as tourtle cover image"
                                className={classes.visibilityItemFullwidth}
                              />
                            )}
                        </div>
                      )}
                      {item === "video" && (
                        <VideoUpload
                          onRef={instance => {
                            this.videoUploadRef = instance;
                          }}
                          tourtleId={this.currentTourtleId}
                          stepId={this.currentStepId}
                          sendUrlBack={this.setStepVideo}
                          removeVideo={this.removeStepVideo}
                          currentUrl={this.state.video}
                          isVideoLoading={this.props.isVideoLoading}
                          square={false}
                          type="step"
                          totalStorageUsed={
                            this.props.self.stats.totalStorageUsed +
                            this.state.storageDelta +
                            this.state.tempStorage
                          }
                          subscriptionValues={
                            this.props.self.subscription.values
                          }
                          handleShowLimitDialog={this.handleShowLimitDialog}
                          handleShowAlmostLimitDialog={
                            this.handleShowAlmostLimitDialog
                          }
                        />
                      )}

                      {item === "audio" && (
                        <AudioUpload
                          onRef={instance => {
                            this.audioUploadRef = instance;
                          }}
                          tourtleId={this.currentTourtleId}
                          stepId={this.currentStepId}
                          sendUrlBack={this.setStepAudio}
                          removeAudio={this.removeStepAudio}
                          currentUrl={this.state.audio}
                          isAudioLoading={this.props.isAudioLoading}
                          square={false}
                          type="step"
                          totalStorageUsed={
                            this.props.self.stats.totalStorageUsed +
                            this.state.storageDelta +
                            this.state.tempStorage
                          }
                          subscriptionValues={
                            this.props.self.subscription.values
                          }
                          handleShowLimitDialog={this.handleShowLimitDialog}
                          handleShowAlmostLimitDialog={
                            this.handleShowAlmostLimitDialog
                          }
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </div>

              <FormControl
                className={classes.textControl}
                variant="standard"
                fullWidth={true}
              >
                <InputLabel
                  className={classes.textLabel}
                  shrink
                  htmlFor="title-input"
                >
                  Item/step Title (optional)
                </InputLabel>
                <CustomInput
                  className={classes.titleInput}
                  inputRef={this.myRef}
                  id="title-input"
                  value={this.state.title}
                  autoFocus={
                    this.props.location.state &&
                    this.props.location.state.focus === "title"
                      ? true
                      : false
                  }
                  multiline={true}
                  maxRows={3}
                  placeholder="Enter a title for this item/step"
                  onChange={e => this.onTitleChange(e.target.value)}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </FormControl>

              <FormControl className={classes.marginControl} variant="standard">
                <InputLabel
                  className={classes.textLabel}
                  shrink
                  htmlFor="description-input"
                >
                  Description (optional)
                </InputLabel>
                <Editor
                  id="description-input"
                  defaultEditorState={this.state.editorState}
                  editorState={this.state.editorState}
                  wrapperClassName={classes.editorWrapper}
                  toolbarClassName={classes.toolbarClass}
                  editorClassName={classes.editorClass}
                  onEditorStateChange={this.onChange}
                  spellCheck={true}
                  stripPastedStyles={false}
                  toolbarOnFocus={true}
                  placeholder="Add more detail here"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontFamily",
                      "fontSize",
                      "colorPicker",
                      "textAlign",
                      "list",
                      "link",
                      "emoji",
                      "history",
                    ],
                    inline: { inDropdown: true },
                    list: {
                      inDropdown: true,
                      options: ["unordered", "ordered", "indent", "outdent"],
                    },
                    link: {
                      inDropdown: true,
                      defaultTargetOption: "_blank",
                      showOpenOptionOnHover: true,
                    },
                    textAlign: {
                      inDropdown: true,
                    },
                    blockType: {
                      inDropdown: true,
                      options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
                    },
                    fontSize: {
                      options: [10, 12, 14, 16, 18, 24],
                    },
                    colorPicker: {
                      colors: [
                        "rgb(165,0,0)",
                        "rgb(255,0,0)",
                        "rgb(255,102,0)",
                        "rgb(255,255,0)",
                        "rgb(0,255,0)",
                        "rgb(0,128,0)",
                        "rgb(0,255,255)",
                        "rgb(0,0,255)",
                        "rgb(128,0,128)",
                        "rgb(150,75,0)",
                        "rgb(192,192,192)",
                        "rgb(0,0,0)",
                      ],
                    },
                    history: { inDropdown: true },
                  }}
                />
              </FormControl>

              <FormControl className={classes.marginControl} variant="standard">
                <InputLabel
                  className={classes.textLabel}
                  shrink
                  htmlFor="description-input"
                >
                  Attachments (optional)
                </InputLabel>
                <div className={classes.uploadContainer}>
                  {attachments.length > 0 && (
                    <form onChange={this.renameAttachment}>
                      <AttachmentInput
                        attachments={attachments}
                        removeAttachment={this.removeAttachment}
                      />
                    </form>
                  )}
                  <div className={classes.padTop}>
                    <PdfUpload
                      tourtleId={this.currentTourtleId}
                      stepId={this.currentStepId}
                      square={false}
                      sendUrlBack={this.setStepFile}
                      type="step"
                      totalStorageUsed={
                        this.props.self.stats.totalStorageUsed +
                        this.state.storageDelta +
                        this.state.tempStorage
                      }
                      subscriptionValues={this.props.self.subscription.values}
                      handleShowLimitDialog={this.handleShowLimitDialog}
                      handleShowAlmostLimitDialog={
                        this.handleShowAlmostLimitDialog
                      }
                    />
                  </div>
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <WarningDialog
          returnFunction={() => {}}
          message="A step must have at least one data item in it (title, description, image, video or audio)."
        />
        <Dialog
          open={this.state.showSaveDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Save changes to your step"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have made changes to your step. Do you want to save them
              before going back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSaveDialogClose}
              className={classes.button}
            >
              No
            </Button>
            <Button
              onClick={this.updateOrCreateNew}
              className={classes.button}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <SubscriptionThresholdDialog
          dialogOpen={this.state.showLimitDialog}
          subscriptionValues={this.props.self.subscription.values}
          totalStorageUsed={
            this.props.self.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideLimitDialog}
          exceededLimits={this.state.exceededLimits}
          action={"uploadItem"}
        />
        <StorageLimit90PercentDialog
          dialogOpen={this.state.showAlmostLimitDialog}
          subscriptionValues={this.props.self.subscription.values}
          totalStorageUsed={
            this.props.self.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideAlmostLimitDialog}
          exceededLimits={this.state.exceededLimits}
        />
      </div>
    );
  }
}

UpdateStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  isAudioLoading: selectors.getIfAudioIsUploading(state),
  isVideoLoading: selectors.getIfVideoIsUploading(state),
  self: selectors.getCurrentUserInfo(state),
});

const mapDispatchToProps = {
  onFetchTourtleItem: actions.tourtleItemRequest,
  onFetchTourtleItemFailure: actions.tourtleItemFailure,
  onFetchTourtleItemSuccess: actions.tourtleItemSuccess,

  onCreateStep: actions.createStepRequest,
  onCreateStepSuccess: actions.createStepAfterSuccess,
  onCreateStepFailure: actions.createStepFailure,

  onUpdateStep: actions.updateStepRequest,
  onUpdateStepSuccess: actions.updateStepSuccess,
  onUpdateStepFailure: actions.updateStepFailure,

  onSetCurrentStepIndex: actions.setCurrentStepIndex,
  setUpdateStepToFalse: actions.setUpdateStepToFalse,

  cancelAddingStep: actions.cancelAddingStep,
  finishStepAdd: actions.finishStepAdd,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  openContentModerationDialog: actions.openContentModerationDialog,
  onAddingStepFromCoverPageOn: actions.fireAddingStepFromCoverPageOn,
  onEditingStepFromCoverPageOn: actions.fireEditingStepFromCoverPageOn,

  updateStorageUsed: actions.updateStorageUsed,

  onDeleteTourtleItem: actions.deleteTourtleRequest,
  onDeleteTourtleItemFailure: actions.deleteTourtleFailure,
  onDeleteTourtleItemSuccess: actions.deleteTourtleSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateStep))
);
