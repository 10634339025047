import React, { Component } from "react";

import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import MainTourtleList from "../TourtleListScreen/MainTourtleList";
import PleaseWaitCircle from "../common/PleaseWaitCircle";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";
import api from "../../api";
import { debounce } from "lodash";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import WarningDialog from "../common/WarningDialog";
import Grid from "@material-ui/core/Grid";

import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import TextButton from "../common/TextButton/TextButton";
import SearchBar from "../common/SearchBar/SearchBar";
import CloseIcon from "@material-ui/icons/CloseRounded";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  searchListContainer: {
    // marginTop: "90px",
  },
  authorListContainer: {
    marginTop: "-20px",
  },
  tourtleContainer: {
    marginTop: "80px",
  },
});

class LinkTourtle extends Component {
  constructor(props) {
    super(props);

    window.onscroll = debounce(() => {
      const {
        search,
        state: { error, isLoading, hasNext },
      } = this;
      if (error || isLoading || !hasNext) return;

      // Checks that the page has scrolled to the bottom
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        search();
      }
    }, 100);
  }

  state = {
    error: false,
    hasNext: false,
    isLoading: false,
    tourtles: [],
    selectedTourtles: [],
    showSaveDialog: false,
    listChanged: false,
    search: "",
    typing: false,
    mount: false,
    searchQuery: "",
    showClearIcon: false,
  };

  async componentDidMount() {
    await this.props.clearCurrentUserTourtleList();
    this.fetchCurrentUserTourtleList();

    if (this.props.location.state.fromCoverPage) {
      this.props.onAddingStepFromCoverPageOn();
    }
  }

  fetchCurrentUserTourtleList = async () => {
    const {
      getCurrentUserTourtleListItems,
      getCurrentUserTourtleListItemsSuccess,
      getCurrentUserTourtleListItemsFailure,
      currentUserCToken,
      currentUserIsFetching,
      currentUserHasNext,
      userInfo,
    } = this.props;
    try {
      if (!currentUserIsFetching && currentUserHasNext) {
        getCurrentUserTourtleListItems();
        const response = await api.fetchAuthorTourtleListItems(
          userInfo.id,
          currentUserCToken,
          this.props.location.state.alreadyLinked
        );
        getCurrentUserTourtleListItemsSuccess(response);
      }
    } catch (error) {
      getCurrentUserTourtleListItemsFailure(error);
    }
  };

  fetchSearchTourtles = async (noCToken, firstTime) => {
    const {
      searchTourtleListRequest,
      searchTourtleListSuccess,
      searchTourtleListFailure,
      cToken,
      isFetching,
      hasNext,
    } = this.props;

    if (!isFetching && hasNext) {
      try {
        searchTourtleListRequest();
        const response = await api.searchTourtleListItems(
          this.state.searchQuery,
          noCToken ? "" : cToken,
          "desc",
          "public,private",
          "all",
          "relevance",
          "tourtle",
          this.props.userInfo.id,
          null,
          this.props.location.state.alreadyLinked
        );
        searchTourtleListSuccess(response);
      } catch (error) {
        searchTourtleListFailure(error);
      }
    }
  };

  setTimer = event => {
    window.scrollTo({ top: 0 });

    clearTimeout(this.timer);
    this.setState({
      searchQuery: event.target.value,
      showClearIcon: event.target.value.length > 0 ? true : false,
    });

    var localThis = this;
    this.timer = setTimeout(function() {
      localThis.handleSearch(true);
    }, 500);
  };

  handleSearch = event => {
    const { onClearSearchList } = this.props;

    clearTimeout(this.timer);
    if (event !== undefined && event !== "clear") {
      onClearSearchList();
      this.fetchSearchTourtles();
    } else {
      if (this.state.searchQuery === "") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.setState({ searchQuery: "", showClearIcon: false });
      }
    }
  };

  handleSaveDialogClose = () => {
    this.setState({
      showSaveDialog: false,
    });

    this.props.cancelAddingStep(true);

    if (this.props.location.state && this.props.location.state.justCreated) {
      this.handleDeleteJustCreatedTourtle();
    } else {
      this.props.location.state.stepIndex
        ? this.props.finishStepAdd(this.props.location.state.stepIndex + 1)
        : this.props.finishStepAdd(0);
      this.props.history.goBack();
    }
  };

  linkTourtle = async validate => {
    const {
      selectedTourtle,
      onLinkTourtleToTourtleRequest,
      onLinkTourtleToTourtleSuccess,
      onLinkTourtleToTourtleFailure,
      onFireWarningDialogOpening,
      selectedTourtleItems,
      onSetActiveTourtleId,
    } = this.props;

    const parentId = this.props.location.state.id;

    onSetActiveTourtleId(parentId);

    var object = {};

    if (this.props.location.state.stepIndex !== undefined) {
      object.position = this.props.location.state.stepIndex;
    }
    object.ids = selectedTourtle.items;
    if (
      this.props.tourtleItem.visibility !== "PRIVATE" &&
      selectedTourtleItems.filter(x => x.visibility === "PRIVATE").length > 0 &&
      validate !== "validated"
    ) {
      onFireWarningDialogOpening();
    } else {
      try {
        onLinkTourtleToTourtleRequest();
        await api.linkTourtleToTourtle(parentId, object);
        onLinkTourtleToTourtleSuccess(
          selectedTourtle.addedTourtles,
          this.props.location.state.stepIndex !== undefined &&
            this.props.location.state.stepIndex !== null
            ? this.props.location.state.stepIndex
            : -1,
          parentId
        );
        this.props.location.state.stepIndex
          ? this.props.finishStepAdd(this.props.location.state.stepIndex + 1)
          : this.props.finishStepAdd(0);
        if (this.props.location.state.justCreated) {
          this.props.history.replace(
            `/tourtle/${this.props.location.state.id}`
          );
        } else {
          this.props.history.goBack();
        }
      } catch (error) {
        onLinkTourtleToTourtleFailure(error);
      }
    }
  };

  onHandleGoBack = () => {
    if (this.props.selectedTourtleList.length) {
      this.setState({ showSaveDialog: true });
    } else {
      this.handleSaveDialogClose();
    }
  };

  async handleDeleteJustCreatedTourtle() {
    const {
      onDeleteTourtleItem,
      onDeleteTourtleItemSuccess,
      onDeleteTourtleItemFailure,
      history,
      location,
    } = this.props;

    const tourtleId = location.state.id;

    try {
      onDeleteTourtleItem();
      const res = await api.deleteTourtle(tourtleId);
      onDeleteTourtleItemSuccess(res);
      history.replace({
        pathname: `/addtourtle/`,
        state: {
          justCreated: true,
        },
      });
    } catch (error) {
      onDeleteTourtleItemFailure(error);
    }
  }

  componentWillUnmount() {
    this.props.onClearSearchList();
    this.props.clearSelectedTourtleList();
    this.props.clearCurrentUserTourtleList();
  }

  handleClear = () => {
    const { onClearSearchList } = this.props;
    this.handleSearch("clear");
    onClearSearchList();
  };

  render() {
    const { classes } = this.props;
    const { isLoading } = this.state;
    const tourtleType =
      this.props.tourtleItem.visibility === "UNLISTED"
        ? "an Unlisted"
        : "a Public";
    return (
      <div className={classes.root}>
        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={
            <SearchBar
              type="search-dialog"
              setTimer={this.setTimer}
              showClearIcon={this.state.showClearIcon}
              handleClear={this.handleClear}
              setFocus={true}
            />
          }
          right={<TextButton outlineButton={true} text="Save" />}
          handleLeftButtonClick={this.onHandleGoBack}
          handleRightButtonClick={this.linkTourtle}
          centerType="search"
        />
        <Grid container justify="center">
          {this.state.searchQuery === "" && this.props.userInfo && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.tourtleContainer}
            >
              <div className={classes.authorListContainer}>
                <MainTourtleList
                  items={this.props.currentUserItems}
                  getNextListItems={this.fetchCurrentUserTourtleList}
                  favoriteTourtles={this.props.favoriteTourtles}
                  isFetching={this.props.currentUserIsFetching}
                  listType="myLinkedTourtle"
                  location={this.props.location}
                  selectAble={true}
                  openTourtle={() => {}}
                />
              </div>
            </Grid>
          )}
          {this.state.searchQuery !== "" && this.props.userInfo && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.tourtleContainer}
            >
              <div className={classes.authorListContainer}>
                <MainTourtleList
                  items={this.props.searchItems.filter(
                    item =>
                      !this.props.location.state.alreadyLinked.includes(item.id)
                  )}
                  getNextListItems={this.fetchSearchTourtles}
                  favoriteTourtles={this.props.favoriteTourtles}
                  isFetching={this.props.isFetching}
                  location={this.props.location}
                  listType="myLinkedTourtle"
                  selectAble={true}
                  openTourtle={() => {}}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Dialog
          open={this.state.showSaveDialog}
          onClose={this.handleSaveDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Save changes to your tourtle"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have made changes to your tourtle. Do you want to save them
              before going back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSaveDialogClose}
              className={classes.button}
            >
              No
            </Button>
            <Button
              onClick={this.linkTourtle}
              color="primary"
              autoFocus
              className={classes.button}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {isLoading && (
          <PleaseWaitCircle loading={this.props.isFetching ? true : false} />
        )}
        <WarningDialog
          type="link-tourtle"
          returnFunction={this.linkTourtle}
          message={
            "This is " +
            tourtleType +
            " tourtle, and you are linking to a Private tourtle, which may not be able to be seen by everyone. Continue?"
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: selectors.getCurrentUserInfo(state),
  searchItems: selectors.getSearchTourtleListItems(state),
  cToken: selectors.getSearchTourtleListCToken(state),
  hasNext: selectors.getSearchTourtleListHasNext(state),
  isFetching: selectors.getSearchTourtleListIsFetching(state),
  currentUserItems: selectors.getCurrentUserTourtleListItems(state),
  currentUserCToken: selectors.getCurrentUserTourtleListCToken(state),
  currentUserIsFetching: selectors.getCurrentUserTourtleListIsFetching(state),
  currentUserHasNext: selectors.getCurrentUserTourtleListHasNext(state),
  favoriteTourtles: selectors.getCurrentUserFavoriteItems(state),
  selectedTourtle: selectors.getSelectedTourtle(state),
  selectedTourtleList: selectors.getSelectedTourtleList(state),
  currentStepIndex: selectors.getCurrentStepIndex(state),
  isAddStepModeOn: selectors.getIsAddStepModeOn(state),
  selectedTourtleItems: selectors.getSelectedTourtles(state),
  tourtleItem: selectors.getTourtleItem(state),
});

const mapDispatchToProps = {
  clearCurrentUserTourtleList: actions.clearCurrentUserTourtleList,

  getCurrentUserTourtleListItems: actions.currentUserTourtleListRequest,
  getCurrentUserTourtleListItemsSuccess: actions.currentUserTourtleListSuccess,
  getCurrentUserTourtleListItemsFailure: actions.currentUserTourtleListFailure,

  searchTourtleListRequest: actions.searchListRequest,
  searchTourtleListFailure: actions.searchListFailure,
  searchTourtleListSuccess: actions.searchListSuccess,

  onClearSearchList: actions.clearListSearch,
  clearSelectedTourtleList: actions.clearSelectedTourtleList,
  finishStepAdd: actions.finishStepAdd,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,

  onLinkTourtleToTourtleRequest: actions.linkTourtleToTourtleRequest,
  onLinkTourtleToTourtleSuccess: actions.linkTourtleToTourtleSuccess,
  onLinkTourtleToTourtleFailure: actions.linkTourtleToTourtleFailure,

  onSetActiveTourtleId: actions.setActiveTourtleId,
  onSetCurrentStepIndex: actions.setCurrentStepIndex,
  cancelAddingStep: actions.cancelAddingStep,
  onAddingStepFromCoverPageOn: actions.fireAddingStepFromCoverPageOn,

  onDeleteTourtleItem: actions.deleteTourtleRequest,
  onDeleteTourtleItemFailure: actions.deleteTourtleFailure,
  onDeleteTourtleItemSuccess: actions.deleteTourtleSuccess,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LinkTourtle))
);
