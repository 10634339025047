import React, { Component } from "react";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import selectors from "../../ducks/selectors";
import actions from "../../ducks/actions";

import api from "../../api";

// Components
import TopBar from "../common/TopBar/TopBar";
import IconButton from "../common/IconButton/IconButton";
import Title from "../common/Title/Title";
import TextButton from "../common/TextButton/TextButton";
// import ImageUpload from "../common/ImageUploadDense";
// import AudioUpload from "../common/AudioUploadDense";
import WarningDialog from "../common/WarningDialog";
import UnsavedChangesDialog from "../common/UnsavedChanges";
import CATEGORIES from "../Utils/constants";
import { isMobile } from "../Utils/detectDevice";
import AddStepDialog from "../common/AddStepDialog";
import SubscriptionThresholdDialog from "../common/SubscriptionThresholdDialog";
import StorageLimit90PercentDialog from "../common/StorageLimit90PercentDialog";
import AddStepItems from "./AddStepItems";

// Material UI
import {
  FormControl,
  Select,
  MenuItem,
  Input,
  InputLabel,
  Grid,
  Checkbox,
  FormControlLabel,
  InputBase,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// Icons
import LockIcon from "@material-ui/icons/Lock";
import LanguageIcon from "@material-ui/icons/Language";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import CloseIcon from "@material-ui/icons/Close";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";

// Other imports
// import { EditorState, convertToRaw } from "draft-js";
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ChipInput from "material-ui-chip-input";
import "./textEditor.css";

import loadable from "@loadable/component";
const ImageUpload = loadable(() => import("../common/ImageUploadDense"));

const CustomInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: "22px",
    },
  },
  input: {
    fontSize: 16,
    fontFamily: ["Roboto", '"Helvetica"', "Arial", "sans-serif"].join(","),
  },
}))(InputBase);

const CustomAccordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(ExpansionPanel);

const CustomAccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
    justifyContent: "unset",
  },
  expandIcon: {
    color: "black",
    position: "relative",
    right: "unset",
    padding: "16px 0px",
    transform: "rotate(0deg)",
    "&$expanded": {
      transform: "rotate(180deg)",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    display: "block",
    flexGrow: 0,
    float: "left",
  },
  expanded: {},
})(ExpansionPanelSummary);

const CustomAccordionDetails = withStyles(theme => ({
  root: {
    padding: "0px",
    display: "unset",
  },
}))(ExpansionPanelDetails);

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: "80px",
    paddingBottom: "80px",
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
  },
  titleInput: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    padding: ".625rem 1rem",
    color: "black",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
  mediaContainer: {
    margin: "0px 0px 20px",
  },
  textControl: {
    width: "100%",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1,
    color: "black !important",
    cursor: "pointer",
    display: "block !important",
    float: "left !important",
  },
  textSubLabel: {
    fontSize: 14,
    lineHeight: 1,
    color: "black !important",
    display: "block !important",
    textAlign: "center",
    marginBottom: 20,
  },
  accordionLabel: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1,
    color: "black",
    cursor: "pointer",
    paddingRight: "0 !important",
  },
  marginControl: {
    width: "100%",
    marginTop: 20,
  },
  visibilityContainer: {
    margin: "20px 0px 15px",
  },
  visibilityItem: {
    width: 75,
  },
  visibilityIconContainer: {
    display: "block",
    margin: 5,
    marginLeft: "auto",
    marginRight: "auto",
  },
  visibilityIconSelected: {
    color: "white",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#F47621",
  },
  visibilityIcon: {
    color: "#000000a3",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 40,
    height: 40,
    padding: 6,
    borderRadius: 10,
    backgroundColor: "#fff",
    border: "1px solid #E5E5E5",
    cursor: "pointer",
  },
  visibilityText: {
    textAlign: "center",
  },
  otherSettingsMargin: {
    marginBottom: 25,
  },
  addIconContainer: {
    color: "#00cc99",
    padding: ".625rem .75rem",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  addIcon: {
    color: "#00cc99",
    float: "left",
    width: 20,
    height: 20,
  },
  addText: {
    marginLeft: -10,
  },
  inactive: {
    opacity: "0.5",
  },
});

class AddTourtleNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: this.props.match.params.id
        ? "Update tourtle"
        : "New tourtle",
      isEditing: this.props.match.params.id,
      confirmedDiscard: false,
      categories: CATEGORIES,
      id: null,
      coverImage: null,
      coverAudio: null,
      title: "",
      description: "",
      tags: [],
      source: "",
      editorState: EditorState.createEmpty(),
      category: "uncategorized",
      visibility: "PRIVATE",
      commentNotifications: true,
      selected: [],
      isDialogOpen: true,
      allowOthersToAddToGroup: false,
      initialAllowOthersToAddToGroup: true,
      deviceIsIOS: false,
      isAuthor: true,
      showPrivateStepWarning: false,
      privateStepNumbers: [],
      privateStepTitles: [],
      steps: null,
      allowOthersToDuplicate: false,
      initialAllowOthersToDuplicate: false,
      allowCheckBoxOnStepItems: false,
      initialAllowCheckBoxOnStepItems: false,
      expanded: false,
      tourtleCreatedOrUpdated: false,
      cleanup: false,
      storageDelta: 0,
      tempStorage: 0,
      showLimitDialog: false,
      showAlmostLimitDialog: false,
      exceededLimits: {
        exceededStorage: false,
        exceededAudioLength: false,
        exceededVideoLength: false,
      },
      coverImageBlob: null,
    };
    this.focusTitle = this.focusTitle.bind(this);
    this.imageUploadRef = React.createRef();
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    api.getStorageUsed(this.props.userInfo.id).then(value => {
      this.props.updateStorageUsed(value);
    });

    if (this.props.match.params.id) {
      this.fetchTourtleItem();
      return;
    }

    const {
      onFireLogoutCurrentUserSuccess,
      history,
      onFireSnackbarOpening,
    } = this.props;

    if (isMobile.iOS()) this.setState({ deviceIsIOS: true });

    if (this.props.location.state) {
      if (this.props.location.state.justCreated) {
        this.setState(this.props.lastCreatedTourtleData);

        window.history.replaceState({}, "");
      }
    }

    try {
      window.scrollTo(0, 0);
      const response = await api.getTourtleId();
      if (response.statusCode === 401) {
        onFireLogoutCurrentUserSuccess();
        history.goBack();
        onFireSnackbarOpening("expired");
      }
      this.setState({ id: response.objectId });
    } catch (error) {
      console.log(error);
    }
  }

  get hasUnsavedChanges() {
    if (!this.state.isEditing) {
      const {
        coverImage,
        coverAudio,
        title,
        editorState,
        tags,
        source,
      } = this.state;

      if (
        coverImage ||
        coverAudio ||
        title !== "" ||
        editorState.getCurrentContent().hasText() ||
        tags.length > 0 ||
        source !== ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.state.coverImage !== this.state.initialCoverImage ||
        this.state.coverAudio !== this.state.initialCoverAudio ||
        this.state.title !== this.state.initialTitle ||
        this.state.editorState.getCurrentContent() !==
          this.state.initialEditorState.getCurrentContent() ||
        this.state.category !== this.state.initialCategory ||
        this.state.visibility !== this.state.initialVisibility ||
        this.state.commentNotifications !==
          this.state.initialCommentNotifications ||
        this.state.source !== this.state.initialSource ||
        this.state.tags !== this.state.initialTags ||
        this.state.allowCheckBoxOnStepItems !==
          this.state.initialAllowCheckBoxOnStepItems ||
        this.state.allowOthersToAddToGroup !==
          this.state.initialAllowOthersToAddToGroup ||
        this.state.allowOthersToDuplicate !==
          this.state.initialAllowOthersToDuplicate
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  handleBrowserBack = () => {
    if (!this.state.confirmedDiscard && !this.state.tourtleCreatedOrUpdated) {
      this.props.onFireUnsavedChangesDialogOpening();
      return false;
    }

    return true;
  };

  handleGoBack = () => {
    const { history, onFireUnsavedChangesDialogOpening } = this.props;

    if (this.hasUnsavedChanges) {
      onFireUnsavedChangesDialogOpening();
    } else {
      history.goBack();
    }
  };

  handleShowLimitDialog = exceededLimits => {
    this.setState({
      showLimitDialog: true,
      exceededLimits: exceededLimits,
    });
  };

  handleHideLimitDialog = () => {
    this.setState({ showLimitDialog: false });
  };

  handleShowAlmostLimitDialog = fileSize => {
    this.setState({ showAlmostLimitDialog: true, tempStorage: fileSize || 0 });
  };

  handleHideAlmostLimitDialog = () => {
    this.setState({ showAlmostLimitDialog: false });
  };

  updateOrCreateNew = () => {
    if (!this.hasUnsavedChanges) return;

    if (this.state.isEditing) {
      return this.handleUpdateTourtle();
    }

    this.createTourtleAndRedirect();
  };

  handleUpdateTourtle = async () => {
    const { openContentModerationDialog } = this.props;
    const type = "tourtle";
    try {
      let finalCategory = null;
      if (this.state.category !== "") {
        finalCategory = this.state.category;
      }
      let finalAllowOthersToAddToGroup = false;
      let finalAllowOthersToDuplicate = false;

      if (this.state.visibility === "PUBLIC") {
        finalAllowOthersToAddToGroup = this.state.allowOthersToAddToGroup;
        finalAllowOthersToDuplicate = this.state.allowOthersToDuplicate;
      }

      this.props.onUpdateTourtle();
      const response = await api.updateTourtle(
        this.props.tourtleItem.id
          ? this.props.tourtleItem.id
          : this.props.match.params.id,
        this.state.coverImage,
        this.state.coverAudio,
        this.state.title.trim(),
        JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        ),
        finalCategory,
        this.state.visibility,
        this.state.commentNotifications,
        this.state.tags || [],
        this.state.source,
        this.state.groups,
        finalAllowOthersToAddToGroup,
        finalAllowOthersToDuplicate,
        this.state.allowCheckBoxOnStepItems,
        !!this.state.cleanup
      );
      if (response.statusCode === 418) {
        this.setState({ showSaveDialog: false });
        openContentModerationDialog(response, type, this.state.title);
      } else {
        this.props.onUpdateTourtleSuccess(response);
        this.setState({ tourtleCreatedOrUpdated: true });
        this.props.history.goBack();
      }
    } catch (error) {
      this.props.onUpdateTourtleFailure(error);
    }
  };

  handleAddFirstStepDialogOpen = () => {
    const { onAddStepDialogOpening } = this.props;
    onAddStepDialogOpening({
      first: true,
      fromCoverPage: true,
    });
  };

  handleAddFirstItem = (redirectTo = "addStep") => {
    this.createTourtleAndRedirect(redirectTo);
  };

  createTourtleAndRedirect = async redirectPath => {
    const {
      title,
      editorState,
      coverImage,
      coverAudio,
      source,
      tags,
      category,
      visibility,
      commentNotifications,
      allowOthersToAddToGroup,
      allowOthersToDuplicate,
      selected,
    } = this.state;
    const {
      history,
      onCreateTourtle,
      onCreateTourtleSuccess,
      onCreateTourtleFailure,
      openContentModerationDialog,
    } = this.props;

    let type = "tourtle";

    let titleStr = title;

    if (title === "") {
      // onFireWarningDialogOpening();
      let indexNumber = 0;
      indexNumber = (this.props.userInfo.stats?.sequence || 0) + 1;
      const paddedNumber = String(indexNumber).padStart(3, "0");
      titleStr = "My tourtle " + paddedNumber;
    }
    try {
      const creationData = {
        expanded: this.state.expanded,
        title: this.state.title,
        editorState: this.state.editorState,
        tags: this.state.tags,
        source: this.state.source,
        category: this.state.category,
        visibility: this.state.visibility,
        commentNotifications: this.state.commentNotifications,
        allowOthersToAddToGroup: this.state.allowOthersToAddToGroup,
        initialAllowOthersToAddToGroup: this.state
          .initialAllowOthersToAddToGroup,
        allowOthersToDuplicate: this.state.allowOthersToDuplicate,
        initialAllowOthersToDuplicate: this.state.initialAllowOthersToDuplicate,
        allowCheckBoxOnStepItems: this.state.allowCheckBoxOnStepItems,
        initialAllowCheckBoxOnStepItems: this.state
          .initialAllowCheckBoxOnStepItems,
      };

      if (this.state.coverImage) {
        const response = await fetch(this.state.coverImage);
        const blob = await response.blob();
        creationData.coverImageBlob = blob;
      }

      onCreateTourtle(creationData);

      let object = {};
      object.id = this.state.id;
      // Assign Title to the local object
      if (titleStr !== "") object.title = titleStr;
      // Assign Description to the local object
      if (editorState.getCurrentContent() !== "") {
        object.description = JSON.stringify(
          convertToRaw(editorState.getCurrentContent())
        );
      }
      // Assign CoverImage to the local object
      if (coverImage !== undefined && coverImage !== null) {
        object.coverImage = coverImage;
      }
      // Assign CoverAudio to the local object
      if (coverAudio !== undefined && coverAudio !== null) {
        object.coverAudio = coverAudio;
      }
      // Assign Tags to the local object
      object.tags = tags;
      // Assign Source to the local object
      object.source = source;
      // Assign Category to the local object
      if (category !== "") {
        object.category = category;
      }
      // Assign Visibility to the local object
      object.visibility = visibility;
      // Assign CommentNotifications to the local object
      object.commentNotifications = commentNotifications;
      object.groups = selected;
      if (visibility === "PUBLIC") {
        object.allowOthersToAddToGroup = allowOthersToAddToGroup;
      } else {
        object.allowOthersToAddToGroup = false;
      }
      if (visibility === "PUBLIC") {
        object.allowOthersToDuplicate = allowOthersToDuplicate;
      } else {
        object.allowOthersToDuplicate = false;
      }
      object.showCheckboxOnSteps = this.state.allowCheckBoxOnStepItems;
      object.cleanup = !!this.state.cleanup;

      const response = await api.createTourtle(object);
      if (response.statusCode === 418) {
        openContentModerationDialog(response, type, title);
      } else {
        if (redirectPath) {
          const newStepId = await api.getTourtleId();
          await onCreateTourtleSuccess(response);
          this.setState({ tourtleCreatedOrUpdated: true });
          history.replace({
            pathname: `/${redirectPath}`,
            state: {
              id: response.id,
              newStepId: newStepId.objectId,
              newTourtle: response.id,
              stepCount: 10,
              alreadyLinked: [response.id],
              fromCoverPage: true,
              stepIndex: 0,
              justCreated: true,
            },
          });
        } else {
          await onCreateTourtleSuccess(response);
          this.setState({ tourtleCreatedOrUpdated: true });
          history.replace(`/tourtle/${response.id}`);
        }
      }
    } catch (error) {
      onCreateTourtleFailure(error);
    }
  };

  // TITLE
  onTitleChange(value) {
    if (value.length <= 70) {
      this.setState({ title: value });
    }
  }

  getSizeForUrl = async (url, removed = false) => {
    fetch(url, {
      headers: {
        Range: "bytes 0-0",
      },
    })
      .then(response => {
        let contentLength = parseInt(
          response.headers.get("Content-Length") || "0"
        );

        if (removed) {
          contentLength = contentLength * -1;
        }

        this.setState({
          storageDelta: this.state.storageDelta + contentLength,
          tempStorage: 0,
        });
      })
      .catch(() => {
        console.log("fetch failed");
      });
  };

  // Image
  setTourtleImage = e => {
    this.setState({ coverImage: e.url });
    this.getSizeForUrl(e.url);
  };
  removeTourtleImage = async () => {
    await this.getSizeForUrl(this.state.coverImage, true);
    this.setState({ coverImage: null, cleanup: true });
  };

  // Audio
  setTourtleAudio = e => {
    this.setState({ coverAudio: e.url });
    this.getSizeForUrl(e.url);
  };
  removeTourtleAudio = async () => {
    await this.getSizeForUrl(this.state.coverAudio, true);
    this.setState({ coverAudio: null, cleanup: true });
  };

  // Description
  onChange = editorState => this.setState({ editorState: editorState });

  // Category
  onCategoryChange = event => this.setState({ category: event.target.value });

  // Source
  onSourceChange = value => this.setState({ source: value });

  // Tags
  handleAddChip = value => {
    if (this.state.tags.length > 5) return;

    const tags = [...this.state.tags].concat([value]);
    this.setState({ tags: tags });
  };

  handleDeleteChip = value => {
    const tags = [...this.state.tags].filter(item => item !== value);
    this.setState({ tags: tags });
  };

  handleLimit = () => {
    if (this.state.tags.length > 5) {
      return false;
    } else {
      return true;
    }
  };

  // Visibility
  changeVisibility = value => {
    if (this.state.visibility !== "PUBLIC" && value === "PUBLIC") {
      this.setState({ allowOthersToAddToGroup: true });
    } else {
      this.setState({ allowOthersToAddToGroup: false });
    }
    this.setState({ visibility: value });
  };

  // Checkboxes
  handleAllowOthersToAddToGroup = () => {
    this.setState({
      allowOthersToAddToGroup: !this.state.allowOthersToAddToGroup,
    });
  };

  onNotificationsChange = () =>
    this.setState({ commentNotifications: !this.state.commentNotifications });

  onChangeDuplicationRule = () => {
    this.setState({
      allowOthersToDuplicate: !this.state.allowOthersToDuplicate,
    });
  };

  onChangeCheckboxRule = () => {
    this.setState({
      allowCheckBoxOnStepItems: !this.state.allowCheckBoxOnStepItems,
    });
  };

  toggleAccordion = event => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  setTourtleState(res) {
    try {
      const newDescription = convertFromRaw(
        JSON.parse(res ? res.description : this.props.tourtleItem.description)
      );
      const newContentState = EditorState.createWithContent(newDescription);
      this.setState({ editorState: newContentState });
      this.setState({ initialEditorState: newContentState });
    } catch (e) {
      const newContentState = EditorState.createWithContent(
        ContentState.createFromText(
          res ? res.description : this.props.tourtleItem.description
        )
      );
      this.setState({ editorState: newContentState });
      this.setState({ initialEditorState: newContentState });
    }
    this.setState({
      id: res.id,
      coverImage: res ? res.coverImage : this.props.tourtleItem.coverImage,
      initialCoverImage: res
        ? res.coverImage
        : this.props.tourtleItem.coverImage,
      coverAudio: res ? res.coverAudio : this.props.tourtleItem.coverAudio,
      initialCoverAudio: res
        ? res.coverAudio
        : this.props.tourtleItem.coverAudio,
      title: res ? res.title : this.props.tourtleItem.title,
      initialTitle: res ? res.title : this.props.tourtleItem.title,
      visibility: res ? res.visibility : this.props.tourtleItem.visibility,
      initialVisibility: res
        ? res.visibility
        : this.props.tourtleItem.visibility,
      commentNotifications: res
        ? res.commentNotifications
        : this.props.tourtleItem.commentNotifications,
      initialCommentNotifications: res
        ? res.commentNotifications
        : this.props.tourtleItem.commentNotifications,
      source: res ? res.source : this.props.tourtleItem.source,
      initialSource: res ? res.source : this.props.tourtleItem.source,
      allowOthersToAddToGroup: res
        ? res.settings.allowOthersToAddToGroup
        : this.props.tourtleItem.settings.allowOthersToAddToGroup,
      initialAllowOthersToAddToGroup: res
        ? res.settings.allowOthersToAddToGroup
        : this.props.tourtleItem.settings.allowOthersToAddToGroup,
      allowOthersToDuplicate: res
        ? res.settings.allowOthersToDuplicate
        : this.props.tourtleItem.settings.allowOthersToDuplicate,
      initialAllowOthersToDuplicate: res
        ? res.settings.allowOthersToDuplicate
        : this.props.tourtleItem.settings.allowOthersToDuplicate,
      allowCheckBoxOnStepItems: res
        ? res.settings.showCheckboxOnSteps
        : this.props.tourtleItem.settings.showCheckboxOnSteps,
      initialAllowCheckBoxOnStepItems: res
        ? res.settings.showCheckboxOnSteps
        : this.props.tourtleItem.settings.showCheckboxOnSteps,
      category: res.category,
      selected: [],
      steps: res.steps,
      initialTags: res.tags,
      tags: res.tags,
      expanded: true,
      cleanup: false,
    });

    if (this.props.tourtleItem.category) {
      this.setState({
        category: res ? res.category : this.props.tourtleItem.category,
        initialCategory: res ? res.category : this.props.tourtleItem.category,
      });
    }

    var tagsArray = [];
    if ((res && res.tags) || this.props.tourtleItem.tags) {
      tagsArray = res ? res.tags : this.props.tourtleItem.tags;
    }

    this.setState({
      tags: tagsArray,
      initialTags: tagsArray,
    });

    var groupsArray = [];
    if ((res && res.groups) || this.props.tourtleItem.groups) {
      (res ? res.groups : this.props.tourtleItem.groups).forEach(group => {
        groupsArray.push(group.id);
      });
    }

    this.setState({
      groups: groupsArray,
      initialGroups: groupsArray,
    });

    if (
      (res && res.publisher && res.publisher.id === this.props.userInfo.id) ||
      (this.props.tourtleItem.publisher &&
        this.props.tourtleItem.publisher.id === this.props.userInfo.id)
    ) {
      this.setState({ isAuthor: true });
    } else {
      this.setState({ isAuthor: false });
    }
  }

  fetchTourtleItem = async () => {
    try {
      this.props.onFetchTourtleItem();
      const response = await api.fetchTourtleItem(this.props.match.params.id);
      await this.props.onFetchTourtleItemSuccess(response);
      this.setTourtleState(response);
      if (this.props.location.state) {
        if (this.props.location.state.focus === "title") {
          this.focusTitle();
        } else if (this.props.location.state.focus === "image") {
          this.imageUploadRef.pressUploadButton();
        }
      }
    } catch (error) {
      this.props.onFetchTourtleItemFailure(error);
    }
  };

  focusTitle() {
    setTimeout(
      function() {
        this.myRef.current.focus();
      }.bind(this),
      250
    );
  }

  discard = () => {
    this.setState({
      confirmedDiscard: true,
    });
    this.props.onFireUnsavedChangesDialogClosing();
    this.props.history.goBack();
  };

  render() {
    const { classes, isImageLoading } = this.props;
    const { categories, id } = this.state;
    return (
      <div className={classes.root}>
        <Prompt
          when={this.hasUnsavedChanges}
          message={this.handleBrowserBack}
        />

        <TopBar
          left={<IconButton icon={<CloseIcon />} />}
          center={<Title title={this.state.headerTitle} />}
          right={
            <TextButton
              text="Save"
              outlineButton={true}
              disabled={!this.hasUnsavedChanges}
            />
          }
          handleLeftButtonClick={this.handleGoBack}
          handleRightButtonClick={this.updateOrCreateNew}
        />
        <div className={classes.content}>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <FormControl className={classes.textControl} variant="standard">
                <InputLabel
                  className={classes.textLabel}
                  shrink
                  htmlFor="title-input"
                >
                  tourtle Name
                </InputLabel>
                <CustomInput
                  className={classes.titleInput}
                  inputRef={this.myRef}
                  id="title-input"
                  value={this.state.title}
                  autoFocus={true}
                  multiline={true}
                  maxRows={3}
                  placeholder="Enter a name for your tourtle list"
                  onChange={e => this.onTitleChange(e.target.value)}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </FormControl>
              <CustomAccordion id="accordion" expanded={this.state.expanded}>
                <CustomAccordionSummary
                  className="custom-accordion-summary"
                  expandIcon={<ExpandIcon />}
                  id="accordion-header"
                  onClick={this.toggleAccordion}
                >
                  <Typography className={classes.accordionLabel}>
                    Show additional options
                  </Typography>
                </CustomAccordionSummary>
                <CustomAccordionDetails>
                  {/* {id !== null && (
                    <FormControl
                      fullWidth
                      className={classes.marginControl}
                      htmlFor="tag-input"
                    >
                      <Grid
                        container
                        justifyContent="center"
                        justify="center"
                        className={classes.mediaContainer}
                      >
                        <Grid
                          justifyContent="center"
                          justify="center"
                          className={classes.visibilityItem}
                          item
                        >
                          <div
                            className={classes.visibilityIconContainer}
                            onClick={() => this.changeVisibility("PRIVATE")}
                          >
                            <PhotoIcon className={classes.visibilityIcon} />
                          </div>
                        </Grid>
                        {!this.state.deviceIsIOS && (
                          <Grid
                            justifyContent="center"
                            justify="center"
                            className={classes.visibilityItem}
                            item
                          >
                            <div
                              className={classes.visibilityIconContainer}
                              onClick={() => this.changeVisibility("UNLISTED")}
                            >
                              <AudioIcon className={classes.visibilityIcon} />
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  )} */}
                  {id !== null && (
                    <div className={classes.uploadContainer}>
                      <Grid
                        container
                        justifyContent="center"
                        justify="center"
                        className={classes.mediaContainer}
                      >
                        <Grid
                          justifyContent="center"
                          justify="center"
                          className={
                            this.state.coverImage === undefined &&
                            isImageLoading === false
                              ? classes.visibilityItem
                              : classes.visibilityItemFullwidth
                          }
                          item
                        >
                          <ImageUpload
                            onRef={instance => {
                              this.imageUploadRef = instance;
                            }}
                            tourtleId={id}
                            sendUrlBack={this.setTourtleImage}
                            removeImage={this.removeTourtleImage}
                            currentUrl={this.state.coverImage}
                            isImageLoading={isImageLoading}
                            square={false}
                            type="tourtle"
                            totalStorageUsed={
                              this.props.userInfo.stats.totalStorageUsed +
                              this.state.storageDelta +
                              this.state.tempStorage
                            }
                            subscriptionValues={
                              this.props.userInfo.subscription.values
                            }
                            handleShowLimitDialog={this.handleShowLimitDialog}
                            handleShowAlmostLimitDialog={
                              this.handleShowAlmostLimitDialog
                            }
                            toBeUploadedImageData={this.state.coverImageBlob}
                          />
                        </Grid>
                        {/* {!this.state.deviceIsIOS && (
                          <Grid
                            justifyContent="center"
                            justify="center"
                            className={
                              this.state.coverAudio === undefined
                                ? classes.visibilityItem
                                : classes.visibilityItemFullwidth
                            }
                            item
                          >
                            <div className={classes.padTop}>
                              <AudioUpload
                                tourtleId={id}
                                sendUrlBack={this.setTourtleAudio}
                                removeAudio={this.removeTourtleAudio}
                                currentUrl={this.state.coverAudio}
                                isAudioLoading={this.props.isAudioLoading}
                                square={false}
                                type="tourtle"
                                totalStorageUsed={
                                  this.props.userInfo.stats.totalStorageUsed +
                                  this.state.storageDelta +
                                  this.state.tempStorage
                                }
                                subscriptionValues={
                                  this.props.userInfo.subscription.values
                                }
                                handleShowLimitDialog={this.handleShowLimitDialog}
                                handleShowAlmostLimitDialog={
                                  this.handleShowAlmostLimitDialog
                                }
                              />
                            </div>
                          </Grid>
                        )} */}
                      </Grid>
                    </div>
                  )}

                  <FormControl
                    className={classes.textControl}
                    variant="standard"
                  >
                    <InputLabel
                      className={classes.textLabel}
                      shrink
                      htmlFor="description-input"
                    >
                      Intro/Summary description
                    </InputLabel>
                    <Editor
                      id="description-input"
                      className="description-input"
                      defaultEditorState={this.state.editorState}
                      editorState={this.state.editorState}
                      wrapperClassName="description-input__wrapper"
                      toolbarClassName="description-input__toolbar"
                      editorClassName="description-input__editor"
                      onEditorStateChange={this.onChange}
                      spellCheck={true}
                      stripPastedStyles={false}
                      toolbarOnFocus={true}
                      placeholder="Enter Intro/Summary text here"
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontFamily",
                          "fontSize",
                          "colorPicker",
                          "textAlign",
                          "list",
                          "link",
                          "emoji",
                          "history",
                        ],
                        inline: { inDropdown: true },
                        list: {
                          inDropdown: true,
                          options: [
                            "unordered",
                            "ordered",
                            "indent",
                            "outdent",
                          ],
                        },
                        link: {
                          inDropdown: true,
                          defaultTargetOption: "_blank",
                          showOpenOptionOnHover: true,
                        },
                        textAlign: {
                          inDropdown: true,
                        },
                        blockType: {
                          inDropdown: true,
                          options: [
                            "Normal",
                            "H1",
                            "H2",
                            "H3",
                            "H4",
                            "H5",
                            "H6",
                          ],
                        },
                        fontSize: {
                          options: [10, 12, 14, 16, 18, 24],
                        },
                        colorPicker: {
                          colors: [
                            "rgb(165,0,0)",
                            "rgb(255,0,0)",
                            "rgb(255,102,0)",
                            "rgb(255,255,0)",
                            "rgb(0,255,0)",
                            "rgb(0,128,0)",
                            "rgb(0,255,255)",
                            "rgb(0,0,255)",
                            "rgb(128,0,128)",
                            "rgb(150,75,0)",
                            "rgb(192,192,192)",
                            "rgb(0,0,0)",
                          ],
                        },
                        history: { inDropdown: true },
                      }}
                    />
                  </FormControl>

                  <FormControl
                    variant="standard"
                    className={classes.marginControl}
                  >
                    <InputLabel
                      className={classes.textLabel}
                      shrink
                      htmlFor="source-input"
                    >
                      Source
                    </InputLabel>
                    <CustomInput
                      className={classes.titleInput}
                      id="source-input"
                      placeholder="You can credit your sources here if you want"
                      value={this.state.source}
                      onChange={e => this.onSourceChange(e.target.value)}
                      inputProps={{
                        autoComplete: "off",
                        style: {
                          padding: 0,
                        },
                      }}
                    />
                  </FormControl>

                  <FormControl fullWidth className={classes.marginControl}>
                    <InputLabel
                      shrink
                      className={classes.textLabel}
                      htmlFor="category-input"
                    >
                      Category
                    </InputLabel>
                    <Select
                      id="category-input"
                      className="category-input"
                      classes={{
                        selectMenu: "category-input__menu",
                        icon: "category-input__icon",
                        select: "category-input__select",
                      }}
                      value={this.state.category}
                      onChange={this.onCategoryChange}
                      input={<Input name="category" id="category-helper" />}
                    >
                      {" "}
                      {Object.entries(categories).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    className={classes.marginControl}
                    htmlFor="tag-input"
                  >
                    <InputLabel shrink className={classes.textLabel}>
                      Tags
                    </InputLabel>
                    <ChipInput
                      id="tag-input"
                      fullWidth
                      className="chip-input"
                      classes={{
                        root: "chip-input__root",
                        chipContainer: "chip-input__container",
                        inputRoot: "chip-input__input-root",
                        input: "chip-input__input-tags",
                        chip: "chip-input__chip-tags",
                      }}
                      value={this.state.tags}
                      onAdd={this.handleAddChip}
                      onDelete={this.handleDeleteChip}
                      placeholder="Enter (up to 6) comma-separated values"
                      newChipKeyCodes={[188, 32, 13]}
                      blurBehavior="add"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    className={`${classes.marginControl} icon-container`}
                    htmlFor="tag-input"
                  >
                    <InputLabel shrink className={classes.textLabel}>
                      Visibility
                    </InputLabel>
                    <div
                      className={
                        this.state.isAuthor ? "" : "update-tourtle__disabled"
                      }
                    >
                      <Grid
                        container
                        justifyContent="center"
                        justify="center"
                        className={classes.visibilityContainer}
                      >
                        <Grid
                          justifyContent="center"
                          justify="center"
                          className={classes.visibilityItem}
                          item
                        >
                          <div
                            className={classes.visibilityIconContainer}
                            onClick={() => this.changeVisibility("PRIVATE")}
                          >
                            <Tooltip
                              title="Only you and groups you share it with can view"
                              aria-label="Add"
                            >
                              <LockIcon
                                className={`${
                                  this.state.visibility === "PRIVATE"
                                    ? classes.visibilityIconSelected
                                    : classes.visibilityIcon
                                } visibility-icon`}
                              />
                            </Tooltip>
                          </div>
                          <Typography className={classes.visibilityText}>
                            Private
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent="center"
                          justify="center"
                          className={classes.visibilityItem}
                          item
                        >
                          <div
                            className={classes.visibilityIconContainer}
                            onClick={() => this.changeVisibility("UNLISTED")}
                          >
                            <Tooltip
                              title="Anyone with a link to it can view"
                              aria-label="Add"
                            >
                              <InsertLinkIcon
                                className={`${
                                  this.state.visibility === "UNLISTED"
                                    ? classes.visibilityIconSelected
                                    : classes.visibilityIcon
                                } visibility-icon`}
                              />
                            </Tooltip>
                          </div>
                          <Typography className={classes.visibilityText}>
                            Unlisted
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent="center"
                          justify="center"
                          className={classes.visibilityItem}
                          item
                        >
                          <div
                            className={classes.visibilityIconContainer}
                            onClick={() => this.changeVisibility("PUBLIC")}
                          >
                            <Tooltip title="Anyone can view" aria-label="Add">
                              <LanguageIcon
                                className={`${
                                  this.state.visibility === "PUBLIC"
                                    ? classes.visibilityIconSelected
                                    : classes.visibilityIcon
                                } visibility-icon`}
                              />
                            </Tooltip>
                          </div>
                          <Typography className={classes.visibilityText}>
                            Public
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </FormControl>
                  <div
                    className={
                      this.state.isAuthor ? "" : "update-tourtle__disabled"
                    }
                  >
                    {this.state.visibility === "PRIVATE" && (
                      <InputLabel className={classes.textSubLabel}>
                        Only you and groups you share it with can view
                      </InputLabel>
                    )}
                    {this.state.visibility === "UNLISTED" && (
                      <InputLabel className={classes.textSubLabel}>
                        Anyone with a link to it can view
                      </InputLabel>
                    )}
                    {this.state.visibility === "PUBLIC" && (
                      <InputLabel className={classes.textSubLabel}>
                        Anyone can view
                      </InputLabel>
                    )}
                  </div>
                  {!this.state.isAuthor && (
                    <div className="update-tourtle__info-text">
                      <InfoIcon className="update-tourtle__info-text__icon" />
                      <Typography className="update-tourtle__info-text__text">
                        Only the Author of a tourtle can change its Visibility
                        setting, not a Collaborator.
                      </Typography>
                    </div>
                  )}
                  <FormControl
                    fullWidth
                    className={classes.otherSettingsMargin}
                  >
                    <InputLabel shrink className={classes.textLabel}>
                      Other settings
                    </InputLabel>
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      className={classes.checkbox}
                      disabled={
                        this.state.visibility !== "PUBLIC" ? true : false
                      }
                      control={
                        <Checkbox
                          checked={this.state.allowOthersToAddToGroup}
                          onChange={this.handleAllowOthersToAddToGroup}
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.notifyMeText}>
                          Allow other users to add this tourtle to groups.
                        </span>
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.commentNotifications}
                          onChange={this.onNotificationsChange}
                          value={this.state.commentNotifications}
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.notifyMeText}>
                          Notify me of Comments made on this tourtle.
                        </span>
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormControlLabel
                      disabled={
                        this.state.visibility !== "PUBLIC" ? true : false
                      }
                      control={
                        <Checkbox
                          value={this.state.allowOthersToDuplicate}
                          checked={this.state.allowOthersToDuplicate}
                          onChange={this.onChangeDuplicationRule}
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.notifyMeText}>
                          Allow this tourtle to be duplicated by any user.
                        </span>
                      }
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.allowCheckBoxOnStepItems}
                          checked={this.state.allowCheckBoxOnStepItems}
                          onChange={this.onChangeCheckboxRule}
                          color="primary"
                        />
                      }
                      label={
                        <span className={classes.notifyMeText}>
                          Display a checkbox on each item/step.
                        </span>
                      }
                    />
                  </FormControl>
                </CustomAccordionDetails>
              </CustomAccordion>
              {!this.props.match.params.id && (
                <AddStepItems
                  stepCount={null}
                  remainingStepCount={1}
                  addItem={() => this.createTourtleAndRedirect("addStep")}
                  addMultipleImages={() =>
                    this.createTourtleAndRedirect("add-multiple-images")
                  }
                  addTourtleLink={() =>
                    this.createTourtleAndRedirect("link-tourtle")
                  }
                />
              )}
            </Grid>
          </Grid>
        </div>

        <WarningDialog
          type="createTourtle"
          returnFunction={this.focusTitle}
          message="Every tourtle must have a title. Please enter a title for your tourtle."
        />

        {this.props.unsavedChangesDialog.open && (
          <UnsavedChangesDialog
            type={this.props.match.params.id ? "updateTourtle" : "addTourtle"}
            isOpen={this.props.unsavedChangesDialog.open}
            cancel={() => this.props.onFireUnsavedChangesDialogClosing()}
            continue={() => this.discard()}
          />
        )}

        {this.props.addStepDialog.open === true && (
          <AddStepDialog
            itemSelected={this.handleAddFirstItem}
            returnSelection={true}
          />
        )}

        <SubscriptionThresholdDialog
          dialogOpen={this.state.showLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideLimitDialog}
          exceededLimits={this.state.exceededLimits}
          action={"uploadItem"}
        />
        <StorageLimit90PercentDialog
          dialogOpen={this.state.showAlmostLimitDialog}
          subscriptionValues={this.props.userInfo.subscription.values}
          totalStorageUsed={
            this.props.userInfo.stats.totalStorageUsed +
            this.state.storageDelta +
            this.state.tempStorage
          }
          handleDialogClose={this.handleHideAlmostLimitDialog}
          exceededLimits={this.state.exceededLimits}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tourtleItem: selectors.getTourtleItem(state),
  tourtleSteps: selectors.getTourtleSteps(state),
  isImageLoading: selectors.getIfImageIsUploading(state),
  isAudioLoading: selectors.getIfAudioIsUploading(state),
  unsavedChangesDialog: selectors.getUnsavedChangesDialog(state),
  userInfo: selectors.getCurrentUserInfo(state),
  addStepDialog: selectors.getAddStepDialog(state),
  lastCreatedTourtleData: selectors.getLastCreatedTourtleData(state),
});

const mapDispatchToProps = {
  onCreateTourtle: actions.createTourtleRequest,
  onCreateTourtleFailure: actions.createTourtleFailure,
  onCreateTourtleSuccess: actions.createTourtleSuccess,

  onUpdateTourtle: actions.updateTourtleRequest,
  onUpdateTourtleSuccess: actions.updateTourtleSuccess,
  onUpdateTourtleFailure: actions.updateTourtleSuccess,

  onFireWarningDialogOpening: actions.fireWarningDialogOpening,
  onFireUnsavedChangesDialogClosing: actions.fireUnsavedChangesDialogClosing,
  onFireUnsavedChangesDialogOpening: actions.fireUnsavedChangesDialogOpening,

  onFireSnackbarOpening: actions.fireSnackbarOpening,
  onFireLogoutCurrentUserSuccess: actions.logoutCurrentUserSuccess,
  openContentModerationDialog: actions.openContentModerationDialog,

  onFetchTourtleItem: actions.tourtleItemRequest,
  onFetchTourtleItemFailure: actions.tourtleItemFailure,
  onFetchTourtleItemSuccess: actions.tourtleItemSuccess,

  onAddStepDialogOpening: actions.fireAddStepDialogOpening,

  updateStorageUsed: actions.updateStorageUsed,
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddTourtleNew))
);
